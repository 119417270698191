var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "海报",
        visible: _vm.downPosterVisible,
        width: "800px",
      },
    },
    [
      _c("div", { staticClass: "down-poster" }, [
        _c(
          "div",
          { staticClass: "down-poster-left" },
          [
            _c("div", { staticClass: "down-poster-title" }, [
              _vm._v("样式选择"),
            ]),
            _c("CodePosterPreviewVue", {
              ref: "codePosterPreviewRef",
              attrs: {
                "poster-img-data": _vm.posterImgData,
                "poster-url": _vm.posterUrl,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "down-poster-right" },
          [
            _c("div", { staticClass: "down-poster-title" }, [
              _vm._v("样式选择"),
            ]),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.posterUrl,
                  callback: function ($$v) {
                    _vm.posterUrl = $$v
                  },
                  expression: "posterUrl",
                },
              },
              _vm._l(_vm.imgData, function (item) {
                return _c(
                  "el-radio",
                  { key: item.id, attrs: { label: item.img } },
                  [
                    _c("span", [_vm._v(_vm._s(item.title))]),
                    _c("div", { staticStyle: { "padding-left": "20px" } }, [
                      _c("img", {
                        staticStyle: { width: "180px", height: "90px" },
                        attrs: { alt: "", src: item.img },
                      }),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("下 载")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }