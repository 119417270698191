var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("el-page-header", {
        attrs: { content: "合同详情" },
        on: { back: _vm.goBack },
      }),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "div",
            { staticClass: "basic" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { border: "", column: 4, size: "medium" },
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("合同编号")]),
                      _vm._v(" " + _vm._s(_vm.detail.code) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("签约方式")]),
                      _vm.detail.contractWay === 1
                        ? _c("span", [_vm._v("纸质")])
                        : _c("span", [_vm._v("电子")]),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("合同房源")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.detail.buildingName) +
                          "-" +
                          _vm._s(_vm.detail.houseName) +
                          " "
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("状态")]),
                      _vm.detail.status === 1
                        ? _c("el-tag", { attrs: { type: "primary" } }, [
                            _vm._v("生效中"),
                          ])
                        : _vm._e(),
                      _vm.detail.status === 0
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("已完结"),
                          ])
                        : _vm._e(),
                      _vm.detail.status === 2
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("已过期"),
                          ])
                        : _vm._e(),
                      _vm.detail.status === 3
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("退租中"),
                          ])
                        : _vm._e(),
                      _vm.detail.status === 5
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("待生效"),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("商户签约人")]),
                      _vm._v(" " + _vm._s(_vm.detail.contractPerson) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("商户签约人电话"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.contactPersonPhone) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("签订时间")]),
                      _vm._v(" " + _vm._s(_vm.detail.contractDate) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("合同文件")]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "blue",
                            "margin-right": "5px",
                            cursor: "pointer",
                          },
                          on: { click: _vm.previewImg },
                        },
                        [_vm._v(" 预览 ")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "blue", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadByBlob(
                                _vm.detail.contractImgs,
                                "name"
                              )
                            },
                          },
                        },
                        [_vm._v(" 下载 ")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rent" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("租赁信息")]),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { border: "", column: 4, size: "medium" },
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("承租人")]),
                      _vm._v(" " + _vm._s(_vm.detail.tenantName) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("承租人手机号"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.tenantPhone) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("承租人身份证"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.tenantIdcard) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("租赁模式")]),
                      _vm._v(" " + _vm._s(_vm.detail.billModeName) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("租金方式")]),
                      _vm._v(" " + _vm._s(_vm.detail.rentalWayName) + " "),
                    ],
                    2
                  ),
                  _vm._l(_vm.subjectList, function (item, index) {
                    return [
                      _c(
                        "el-descriptions-item",
                        { key: index },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(_vm._s(item.subjectName)),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(item.defaultUnitPrice) +
                              _vm._s(item.unitName) +
                              " "
                          ),
                        ],
                        2
                      ),
                    ]
                  }),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("开始租赁日期"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.startDate) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("结束租赁日期"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.endDate) + " "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("应支付日")]),
                      _vm._v(" " + _vm._s(_vm.detail.billPayDay) + "号 "),
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("可支付日提前"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.detail.aheadPayDay) + "天 "),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rent" },
            [
              _c("div", { staticClass: "title1" }, [_vm._v("入住人信息")]),
              _vm._l(_vm.renterList, function (item) {
                return _c("div", { key: item.id, staticClass: "renter" }, [
                  _c("div", [_c("span", [_vm._v(_vm._s(item.name))])]),
                  _c("div", { staticStyle: { margin: "10px 0" } }, [
                    _c("span", [_vm._v("手机号: " + _vm._s(item.phone))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("身份证号: " + _vm._s(item.idcard))]),
                  ]),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "rent1" },
            [
              _c("div", { staticClass: "title1" }, [_vm._v("合同期账单")]),
              _c("div", { staticClass: "bill" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    "应收金额: " +
                      _vm._s(_vm.totalDetail.receivableAmount) +
                      "元"
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    "已支付费用: " +
                      _vm._s(_vm.totalDetail.hasPayEdAmount) +
                      "元"
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    "未支付费用: " +
                      _vm._s(_vm.totalDetail.noPayEdAmount) +
                      "元"
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    " 平台服务费: " +
                      _vm._s(_vm.totalDetail.billServiceAmount) +
                      "元 "
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _vm._v(
                    "实收金额: " + _vm._s(_vm.totalDetail.realAmount) + "元"
                  ),
                ]),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    border: "",
                    data: _vm.billList,
                    "max-height": "400",
                    stripe: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function () {
                        return [
                          _c("el-image", {
                            staticClass: "vab-data-empty",
                            attrs: {
                              src: require("@/assets/empty_images/data_empty.png"),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "账单号",
                      prop: "id",
                      "show-overflow-tooltip": "",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "应收金额(元)",
                      prop: "receivableAmount",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "平台服务费(元)",
                      prop: "billServiceAmount",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "实收金额(元)",
                      prop: "realAmount",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "账单状态",
                      prop: "billStatusName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "账单周期",
                      prop: "billCycle",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "应支付日",
                      prop: "billDate",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "逾期情况",
                      prop: "overDueInfo",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "支付方式",
                      prop: "paymentWayName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "支付状态",
                      prop: "payStatusName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "支付时间",
                      prop: "realPayTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "自动提现时间",
                      prop: "withdrawTime",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      label: "操作",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheckDetail(
                                      scope.row.id,
                                      scope.row.billCycle
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 详情 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheckService(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(" 服务费 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  limit: _vm.listQuery.pageSize,
                  page: _vm.listQuery.pageNumber,
                  "total-count": _vm.totalCount,
                },
                on: {
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNumber", $event)
                  },
                  pagination: _vm.getBillList,
                },
              }),
            ],
            1
          ),
          _c("Dialog", {
            attrs: {
              height: _vm.dailog.height,
              "model-value": _vm.dailog.visible,
              showfoot: _vm.dailog.showfoot,
              title: _vm.dailog.title,
              visible: _vm.dailog.visible,
              width: "900px",
            },
            on: {
              handleCloseDialog: _vm.handleCloseDialog,
              handleConfirm: _vm.handleConfirm,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          border: "",
                          data: _vm.tableDataBill,
                          stripe: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "费用类型",
                            prop: "billSubjectName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "用量",
                            prop: "costMeterVal",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "单价",
                            prop: "unitPrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "逾期情况",
                            prop: "overDueInfo",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "支付方式",
                            prop: "paymentWayName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "支付状态",
                            prop: "payStatusName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "费用生成时间",
                            prop: "createTime",
                            width: "200",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "金额(元)",
                            prop: "receivableAmount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("Dialog", {
            attrs: {
              height: _vm.dailog.height,
              "model-value": _vm.dailog.visible,
              showfoot: _vm.dailog.showfoot,
              title: _vm.dailog.title,
              visible: _vm.dailog.visibleService,
              width: _vm.dailog.width,
            },
            on: {
              handleCloseDialog: _vm.handleCloseDialog,
              handleConfirm: _vm.handleConfirm,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          border: "",
                          data: _vm.tableDataService,
                          stripe: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("el-image", {
                                  staticClass: "vab-data-empty",
                                  attrs: {
                                    src: require("@/assets/empty_images/data_empty.png"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "费用类型",
                            prop: "name",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "收费标准",
                            prop: "unit",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "数量",
                            prop: "count",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "收费金额(元)",
                            prop: "amount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }