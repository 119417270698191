<template>
  <div class="index-container">
    <el-form label-width="80px" :model="listQuery" size="small">
      <el-row>
        <!--<el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="详细地址">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入详细地址"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>-->
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="小区名称">
            <el-input
              v-model="listQuery.communityName"
              clearable
              placeholder="请输入小区名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入楼栋名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="地区">
            <el-cascader
              v-model="areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vab-query-form-top-panel>
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-button
            icon="el-icon-refresh-left"
            size="mini"
            type="primary"
            @click="buildingLongitudeAndLatitudeInit"
          >
            初始化楼栋经纬度
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form-top-panel>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="省/市/区"
        show-overflow-tooltip
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.province }}/{{ scope.row.city }}/{{ scope.row.district }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="小区名称"
        prop="communityName"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="name"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        label="详细地址"
        prop="address"
        show-overflow-tooltip
        width="200"
      />
      <!-- <el-table-column align="center" label="经度" prop="latitude" />
      <el-table-column align="center" label="纬度" prop="longitude" /> -->
      <el-table-column align="center" label="楼层数" prop="floorNum" />
      <el-table-column
        align="center"
        label="房间总数"
        prop="houseCount"
        width="120"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
        width="200"
      />
      <!-- <el-table-column
        align="center"
        label="修改时间"
        prop="updateTime"
        show-overflow-tooltip
        width="200"
      /> -->
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ drawerData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">小区名称</template>
            {{ drawerData.communityName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">楼栋名称</template>
            {{ drawerData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">所在省份</template>
            {{ drawerData.province }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">所在地市</template>
            {{ drawerData.city }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">所在区县</template>
            {{ drawerData.district }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">详细地址</template>
            {{ drawerData.address }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">楼层数</template>
            {{ drawerData.floorNum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">经度</template>
            {{ drawerData.latitude }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">纬度</template>
            {{ drawerData.longitude }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">房间总数</template>
            {{ drawerData.houseCount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{ drawerData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算账户</template>
            {{ drawerData.openBank }} {{ drawerData.bankCardNo }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label">单独账号结算信息</template>
            {{ drawerData.singleOpenBank }} {{ drawerData.singleBankCardNo }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label">修改时间</template>
            {{ drawerData.updateTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import {
    buildingList,
    buildingDetail,
    buildingLongitudeAndLatitudeInitApi,
  } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'
  import { regionList } from '@/api/system/user'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'Building',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          shopName: '',
          communityName: '',
          name: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
        areaCode: '',
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        dailog: {
          height: null,
          title: '',
          visible: false,
          visibleService: false,
          width: '700px',
          showfoot: false,
        },
      }
    },
    mounted() {
      this.getList()
      this.getRegiList()
    },
    methods: {
      buildingLongitudeAndLatitudeInit() {
        buildingLongitudeAndLatitudeInitApi().then((res) => {
          this.commonMessage(res)
          console.log(res)
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.loading = false
      },
      getList() {
        this.loading = true
        buildingList(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handleChange(val) {
        console.log(val)
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
      },
      getRegiList() {
        regionList().then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.areaList = res.data
          }
        })
      },
      handleCheckDetail(row) {
        console.log(row.id)
        this.dailog.visible = true
        this.dailog.title = '楼栋详情'
        buildingDetail({ id: row.id }).then((res) => {
          if (res.code === 0) {
            // const { list, totalRow } = res.data
            // this.tableData = list
            // this.totalCount = totalRow
            // this.loading = false
            this.drawerData = res.data
            console.log('res', res.data)
          }
        })
        // this.drawerData = row
      },
      handleClose() {
        this.drawer = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
    }
  }
  ::v-deep() .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    font-size: 15px !important;
  }
</style>
