<template>
  <el-dialog
    :before-close="handleClose"
    title="海报"
    :visible="downPosterVisible"
    width="800px"
  >
    <div class="down-poster">
      <div class="down-poster-left">
        <div class="down-poster-title">样式选择</div>
        <CodePosterPreviewVue
          ref="codePosterPreviewRef"
          :poster-img-data="posterImgData"
          :poster-url="posterUrl"
        />
      </div>
      <div class="down-poster-right">
        <div class="down-poster-title">样式选择</div>
        <el-radio-group v-model="posterUrl">
          <el-radio v-for="item in imgData" :key="item.id" :label="item.img">
            <span>{{ item.title }}</span>
            <div style="padding-left: 20px">
              <img alt="" :src="item.img" style="width: 180px; height: 90px" />
            </div>
          </el-radio>
        </el-radio-group>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">下 载</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import { getPosterImg } from '@/api/basicssetting/newIndex'
  import CodePosterPreviewVue from '@/views/basicssetting/components/codePosterPreview.vue'
  import { getUploadToken } from '@/api/common/index'
  import { publishDetail } from '@/api/feature/feature'
  import {
    houseShareImgCode,
    addHouseShareImgCode,
  } from '@/api/feature/newFeature'
  import { downImgUrl } from '@/utils/index'
  import axios from 'axios'
  export default {
    name: '',
    components: { CodePosterPreviewVue },
    props: {
      downPosterVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      posterImgData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        posterUrl: '',
        imgData: [],
      }
    },
    created() {
      this.getPosterImgData()
    },
    methods: {
      getPublishDetail(id, imgList) {
        publishDetail({ id: id }).then((res) => {
          this.imgData = [
            { title: '房源实拍图', img: res.data.housePhotoList[0].url },
          ].concat(imgList)
          this.posterUrl = this.imgData[0].img
        })
      },
      getPosterImgData() {
        getPosterImg({ client: 'PLATFORM', status: 'ENABLED' }).then((res) => {
          this.getPublishDetail(this.posterImgData.id, res.data.records)
        })
      },
      handleClose() {
        this.$emit('update:downPosterVisible', false)
      },
      base64toFile(dataBase64, filename = 'file') {
        const arr = dataBase64.split(',')
        const mime = arr[0].match(/:(.*?);/)[1] // 获取file文件流的type名称
        const suffix = mime.split('/')[1] // 获取文件类型
        const bstr = window.atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime,
        })
      },
      handleConfirm() {
        houseShareImgCode({
          posterUrl: this.posterUrl,
          businessType: 'HOUSE_POSTER',
          businessId: this.posterImgData.houseId,
        }).then(async (res) => {
          if (res.data === '') {
            let showImgUrl = await this.$refs.codePosterPreviewRef.down()
            let blob = this.base64toFile(showImgUrl, 'image/jpeg')
            console.log(blob, '22222')
            const fileName = blob.name
            let ext = fileName.substring(
              fileName.lastIndexOf('.') + 1,
              fileName.length
            )
            getUploadToken({ extension: ext }).then((getRes) => {
              const { token, key } = getRes.data
              const url = 'http://upload-z2.qiniup.com/'
              let formData = new FormData()
              formData.append('file', blob)
              formData.append('data', blob)
              formData.append('key', key)
              formData.append('token', token)
              let config = {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
              axios.post(url, formData, config).then(() => {
                addHouseShareImgCode({
                  businessType: 'HOUSE_POSTER',
                  posterUrl: this.posterUrl,
                  url: getRes.data.url,
                  businessId: this.posterImgData.houseId,
                }).then(() => {
                  this.$emit('update:downPosterVisible', false)
                })
              })
            })
          } else {
            downImgUrl(
              res.data,
              `${this.posterImgData.communityName}${this.posterImgData.buildingName}${this.posterImgData.houseName}`
            )
            this.$emit('update:downPosterVisible', false)
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .down-poster {
    display: flex;
    padding: 0 20px;
    .down-poster-left {
      width: 400px;
    }
    .down-poster-right {
      flex: 1;
    }
    .down-poster-title {
      height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #5e6d82;
      margin-bottom: 20px;
      letter-spacing: 0;
    }
  }
</style>
