<template>
  <div class="index-container meter-reading">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="设备编号">
            <el-input
              v-model="listQuery.deviceNo"
              clearable
              placeholder="设备编号"
            />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="楼栋名称"
            />
          </el-form-item>
          <el-form-item label="房间号">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="房间号"
            />
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="listQuery.typeNumber" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="水表" value="SMART_WATER_METER" />
              <el-option label="电表" value="SMART_ELECTRIC_METER" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="设备编号"
        prop="deviceNo"
        width="130"
      />
      <el-table-column align="center" label="类型" prop="">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.typeNumber === 'SMART_ELECTRIC_METER'"
            type="success"
          >
            电表
          </el-tag>
          <el-tag v-else type="primary">水表</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="开关状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.deviceStatus === 1" type="success">
            开启
          </el-tag>
          <el-tag v-else type="info">关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="当前信号值"
        prop="signalLvl"
        width="100"
      />
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        width="120"
      />
      <el-table-column
        align="center"
        label="绑定房间号"
        prop="houseName"
        width="100"
      />
      <el-table-column align="center" label="当前读数" prop="meterVal" />
      <el-table-column
        align="center"
        label="获取时间"
        prop="meterDate"
        width="160"
      />
      <el-table-column align="center" fixed="right" label="操作" width="240">
        <template slot-scope="scope">
          <el-button type="text" @click="getMeterVal(scope.row, scope.$index)">
            获取读数
          </el-button>
          <el-button type="text" @click="getRecord(scope.row)">
            抄表记录
          </el-button>
          <el-button type="text" @click="getRoomRecord(scope.row)">
            设备绑定记录
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <meterReadingRecordsVue
      v-if="meterReadingRecordsVisible"
      :meter-reading-records-data="meterReadingRecordsData"
      :meter-reading-records-visible.sync="meterReadingRecordsVisible"
    />
    <roomBindingRecordVue
      v-if="roomBindingRecordVisible"
      :room-binding-record-data="roomBindingRecordData"
      :room-binding-record-visible.sync="roomBindingRecordVisible"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { smartDevice } from '@/api/build/newBuild'
  import { shopSmartDevice } from '@/api/build/newBuildIot'
  import meterReadingRecordsVue from './components/meterReadingRecords.vue'
  import roomBindingRecordVue from './components/roomBindingRecord.vue'
  export default {
    name: 'MeterReading',
    components: {
      Pagination,
      meterReadingRecordsVue,
      roomBindingRecordVue,
    },
    data() {
      return {
        meterReadingRecordsData: {},
        roomBindingRecordData: {},
        meterReadingRecordsVisible: false,
        roomBindingRecordVisible: false,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          deviceNo: '',
          shopName: '',
          buildingName: '',
          houseName: '',
          typeNumber: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      if (this.$route.query?.adminPhone) {
        this.listQuery.adminPhone = this.$route.query?.adminPhone
      }
      this.fetchData()
    },
    methods: {
      getMeterVal(row, index) {
        this.$message('获取中')
        shopSmartDevice({ deviceNo: row.deviceNo }).then((res) => {
          if (res.code === 0) {
            this.tableData.splice(index, 1, { ...row, ...res.data })
            this.$message.success('获取成功')
          } else {
            this.$message.danger('获取失败')
          }
        })
      },
      getRecord(row) {
        this.meterReadingRecordsVisible = true
        this.meterReadingRecordsData = row
      },
      getRoomRecord(row) {
        this.roomBindingRecordVisible = true
        this.roomBindingRecordData = row
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        this.$router.push({
          path: '/merchant/device/manage',
          query: {
            id: row.id,
            shopName: row.shopName,
          },
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await smartDevice({ ...this.queryForm, ...params })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          deviceNo: '',
          shopName: '',
          buildingName: '',
          houseName: '',
          typeNumber: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
