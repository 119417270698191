<template>
  <el-dialog
    :before-close="handleClose"
    title="房间绑定记录"
    :visible="roomBindingRecordVisible"
    width="800px"
  >
    <vab-query-form>
      <el-form
        ref="formTop"
        :inline="true"
        label-width="80px"
        @submit.native.prevent
      >
        <el-form-item label="设备编号：">
          {{ roomBindingRecordData.deviceNo }}
        </el-form-item>
        <el-form-item label="类型：">
          {{
            roomBindingRecordData.typeNumber === 'SMART_ELECTRIC_METER'
              ? '电表'
              : '水表'
          }}
        </el-form-item>
      </el-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="">
            <el-date-picker
              v-model="creatTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table v-loading="loading" border :data="tableData" stripe>
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="楼栋名称" prop="buildingName" />
      <el-table-column align="center" label="绑定事件" prop="remark" />
      <el-table-column align="center" label="时间" prop="bindTime" />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleConfirm">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { bindLogs } from '@/api/build/newBuild'
  let dayjs = require('dayjs')
  export default {
    name: '',
    components: { Pagination },
    props: {
      roomBindingRecordVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      roomBindingRecordData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        creatTime: null,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleClose() {
        this.$emit('update:roomBindingRecordVisible', false)
      },
      handleConfirm() {
        this.$emit('update:roomBindingRecordVisible', false)
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
          startTime: this.creatTime
            ? dayjs(this.creatTime[0]).format('YYYY-MM-DD')
            : undefined,
          endTime: this.creatTime
            ? dayjs(this.creatTime[1]).format('YYYY-MM-DD')
            : undefined,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await bindLogs(
            {
              ...this.queryForm,
              ...params,
            },
            this.roomBindingRecordData.id
          )
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
        }
        this.creatTime = null
        this.fetchData()
      },
    },
  }
</script>

<style scoped lang="scss">
  .down-poster {
    display: flex;
    padding: 0 20px;
    .down-poster-left {
      width: 400px;
    }
    .down-poster-right {
      flex: 1;
    }
    .down-poster-title {
      height: 20px;
      font-weight: 400;
      font-size: 14px;
      color: #5e6d82;
      margin-bottom: 20px;
      letter-spacing: 0;
    }
  }
</style>
