<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="dailog.showfoot"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCancel"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        :inline="true"
        label-width="80px"
        :model="listQuery"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item label="机制" prop="random">
          <el-select
            v-model="listQuery.random"
            placeholder="请选择机制"
            style="width: 400px"
            @change="handleChangeRandom"
          >
            <el-option
              v-for="item in randomOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="密码" prop="content">
          <el-input
            v-model="listQuery.content"
            clearable
            placeholder="6-8位数字"
            show-password
            style="width: 400px"
            type="password"
          >
            <el-button
              v-if="listQuery.random"
              slot="append"
              icon="el-icon-refresh"
              @click="handleChangeRandom"
            />
          </el-input>
        </el-form-item>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'

  export default {
    components: {
      Dialog,
    },
    props: {
      keyTypeOptions: {
        type: Array,
        default: () => [],
      },
      validityTypeOptions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        rules: {
          random: [
            {
              required: true,
              message: '请选择机制',
              trigger: 'change',
            },
          ],
          content: [
            { required: true, message: '请输入密码', trigger: 'change' },
          ],
        },
        dailog: {
          height: 400,
          title: '修改密码',
          visible: false,
          showfoot: true,
          visibleAddOrEdit: false,
          width: '520px',
          top: '5vh',
        },
        listQuery: {
          random: undefined,
          content: undefined,
        },
        randomOptions: [
          {
            label: '随机',
            value: 1,
          },
          {
            label: '自定义',
            value: 0,
          },
        ],
      }
    },
    methods: {
      generateRandomNumber(minLength, maxLength) {
        const min = Math.pow(10, minLength - 1) // 10^(minLength-1) 是最小值
        const max = Math.pow(10, maxLength) - 1 // 10^maxLength - 1 是最大值
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      show(records) {
        this.dailog.visible = true
        this.listQuery = { ...records }
      },
      handleCancel() {
        this.handleReset()
        this.dailog.visible = false
      },
      handleReset() {
        this.$refs['form'].resetFields()
      },
      handleChangeRandom(val) {
        if (val) {
          this.listQuery.content = this.generateRandomNumber(6, 8)
        } else {
          this.listQuery.content = undefined
        }
      },
      handleConfirm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              random: this.listQuery.random,
              content: this.listQuery.content,
              id: this.listQuery.id,
              keyType: this.listQuery.keyType,
              validityType: this.listQuery.validityType,
              startDate: this.listQuery.startDate
                ? this.listQuery.startDate
                : undefined,
              endDate: this.listQuery.endDate
                ? this.listQuery.endDate
                : undefined,
              name: this.listQuery.name,
            }
            this.$emit('handleSubmitPassword', params)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    color: #8a96a8;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
</style>
