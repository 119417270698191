<template>
  <div class="index-container meter-reading">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="楼栋名称"
            />
          </el-form-item>
          <el-form-item label="房间号">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="房间号"
            />
          </el-form-item>
          <el-form-item label="地区">
            <el-cascader
              v-model="listQuery.areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item label="上架状态">
            <el-select v-model="listQuery.shelfStatus" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option label="未上架" :value="0" />
              <el-option label="已上架" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <vab-query-form-top-panel>
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            native-type="submit"
            type="primary"
            @click="uploadBuildingCode"
          >
            下载楼栋二维码
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form-top-panel>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          <span>
            {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="房源ID" prop="houseId" />
      <el-table-column align="center" label="商户" prop="shopName" />
      <el-table-column align="center" label="小区名称" prop="communityName" />
      <el-table-column align="center" label="楼栋名称" prop="buildingName" />
      <el-table-column align="center" label="房间号" prop="houseName" />
      <el-table-column align="center" label="省/市/区" prop="productName">
        <template slot-scope="scope">
          {{ scope.row.province }}/{{ scope.row.city }}/{{ scope.row.district }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="房源状态" prop="houseStatus">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.houseStatus == 1" type="success">
            空房可租
          </el-tag>
          <el-tag v-if="scope.row.houseStatus == 3">在租中</el-tag>
          <el-tag v-if="scope.row.houseStatus == 2" type="danger">
            空房不可租
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="上架状态" prop="shelfStatus">
        <template slot-scope="scope">
          <span
            v-if="scope.row.houseStatus == 1 && scope.row.shelfStatus === 1"
          >
            已上架
          </span>
          <span
            v-if="
              scope.row.houseStatus == 1 &&
              (scope.row.shelfStatus === 0 || scope.row.shelfStatus === 2)
            "
          >
            未上架
          </span>
          <span v-if="scope.row.houseStatus == 3 || scope.row.houseStatus == 2">
            不可上架
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        prop="productName"
        width="200"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">详情</el-button>
          <el-button
            :disabled="scope.row.shelfStatus !== 1"
            type="text"
            @click="setHot(scope.row)"
          >
            {{ scope.row.hotFlag == 1 ? '取消热门' : '设置热门' }}
          </el-button>
          <el-button
            :disabled="scope.row.shelfStatus !== 1"
            type="text"
            @click="posterImg(scope.row)"
          >
            海报
          </el-button>
          <el-button
            :disabled="scope.row.shelfStatus !== 1"
            type="text"
            @click="codeImg(scope.row)"
          >
            二维码
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :confirm-bnt-text="dailog.confirmBntText"
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="uploadBuildingData"
          label-width="90px"
          :model="uploadBuildingData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="小区名称" prop="communityId">
              <el-select
                v-model="uploadBuildingData.community"
                filterable
                placeholder="请输入关键词搜索小区"
                style="width: 90%"
                value-key="id"
                @change="changeCommunityName"
              >
                <el-option
                  v-for="item in communityOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="楼栋名称" prop="buildingId">
              <el-select
                v-model="uploadBuildingData.building"
                filterable
                placeholder="请输入关键词搜索楼栋"
                style="width: 90%"
                value-key="id"
                @change="changeBuilding"
              >
                <el-option
                  v-for="item in buildingOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <HouseSquareDetail
      v-if="squareVisible"
      :form-data="formData"
      :square-visible.sync="squareVisible"
    />
    <DownPosterImgVue
      v-if="downPosterVisible"
      :down-poster-visible.sync="downPosterVisible"
      :poster-img-data="posterImgData"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import HouseSquareDetail from './components/houseSquareDetail.vue'
  import { getHousePublish, houseShareImgCode } from '@/api/feature/newFeature'
  import { setHot, publishDetail } from '@/api/feature/feature'
  import { regionList } from '@/api/system/user'
  import { downImgUrl } from '@/utils/index'
  import DownPosterImgVue from './components/downPosterImg.vue'
  import Dialog from '@/components/dialog'
  import { getCommunityNameApi } from '@/api/build/community'
  import { findBuildingByCommunityId } from '@/api/build/newBuild'
  export default {
    name: 'HouseSquare',
    components: {
      Pagination,
      Dialog,
      HouseSquareDetail,
      DownPosterImgVue,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        downPosterVisible: false,
        posterImgData: {},
        areaList: [],
        formData: {},
        uploadBuildingData: {
          community: '',
          communityId: '',
          communityName: '',
          building: '',
          buildingId: '',
          buildingName: '',
          province: '',
          city: '',
          district: '',
        },
        communityOptions: [],
        buildingOptions: [],
        rules: {
          buildingId: [
            { required: true, message: '请选择楼栋', trigger: 'blur' },
          ],
        },
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        dailog: {
          height: 0,
          title: '',
          visible: false,
          width: '0px',
          confirmBntText: '下 载',
        },
        squareVisible: false,
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          buildingName: '',
          houseName: '',
          areaCode: '',
          shelfStatus: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          communityName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [],
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
      this.getRegiList()
    },
    methods: {
      //下载楼栋二维码
      uploadBuildingCode() {
        this.dailog.visible = true
        this.dailog.title = '下载楼栋二维码'
        this.dailog.height = 100
        this.dailog.width = '400px'
        getCommunityNameApi().then((res) => {
          if (res.code === 0) {
            this.communityOptions = res.data
          }
        })
      },
      changeCommunityName(item) {
        findBuildingByCommunityId({ communityId: item.id }).then((res) => {
          if (res.code === 0) {
            this.buildingOptions = res.data
          }
        })
        this.uploadBuildingData.communityId = item.id
        this.uploadBuildingData.communityName = item.name
      },
      changeBuilding(item) {
        this.uploadBuildingData.buildingId = item.id
        this.uploadBuildingData.buildingName = item.name
        this.uploadBuildingData.city = item.city
        this.uploadBuildingData.province = item.province
        this.uploadBuildingData.district = item.district
        console.log(this.uploadBuildingData)
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.uploadBuildingData.building = {}
        this.uploadBuildingData.communityId = ''
        this.uploadBuildingData.buildingId = ''
        this.uploadBuildingData.community = {}
        this.uploadBuildingData.communityName = ''
        this.uploadBuildingData.buildingName = ''
        this.uploadBuildingData.city = ''
        this.uploadBuildingData.province = ''
        this.uploadBuildingData.district = ''
      },
      //提交下载二维码
      async handleConfirm() {
        console.log(this.uploadBuildingData.buildingId)
        this.$refs.uploadBuildingData.validate(async (valid) => {
          if (valid) {
            let res = await this.getHouseShareImgCode(
              'BUILDING_QR_CODE',
              this.uploadBuildingData.buildingId
            )
            if (res.code === 0) {
              downImgUrl(
                res.data,
                `${this.uploadBuildingData.communityName}${this.uploadBuildingData.buildingName}-${this.uploadBuildingData.province}${this.uploadBuildingData.city}${this.uploadBuildingData.district}`
              )
              this.handleCloseDialog()
            }
          }
        })
      },
      handleChange(val) {
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
      },
      getRegiList() {
        regionList().then((res) => {
          if (res.code === 0) {
            this.areaList = res.data
          }
        })
      },
      detail(row) {
        this.squareVisible = true
        publishDetail({ id: row.id }).then((res) => {
          this.formData = res.data
        })
      },
      setHot(row) {
        const params = {
          id: row.id,
          hotFlag: row.hotFlag == 1 ? 0 : 1,
        }
        setHot(params).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.fetchData()
          }
        })
      },
      async posterImg(row) {
        let codeImg = await this.getHouseShareImgCode(
          'HOUSE_QR_CODE',
          row.houseId
        )
        this.posterImgData = {
          houseId: row.houseId,
          codeImg: codeImg.data,
          id: row.id,
          buildingName: row.buildingName,
          houseName: row.buildingName,
          publishRentPrice: row.publishRentPrice,
          city: row.city,
          district: row.district,
          houseShape: row.houseShape,
          area: row.area,
          toward: row.toward,
          communityName: row.communityName,
          rentWay:
            row.rentWay === 1 ? '整租' : row.rentWay === 2 ? '合租' : '其它',
          publishName: row.publishName,
        }
        this.downPosterVisible = true

        // this.$nextTick(() => {
        //   this.$refs.codePosterPreviewRef.down()
        //   console.log(this.$refs.codePosterPreviewRef, '222')
        // })
      },
      getHouseShareImgCode(businessType, houseId) {
        return new Promise((resolve) => {
          houseShareImgCode({
            businessType: businessType,
            businessId: houseId,
          }).then((res) => {
            if (res.code === 0) {
              resolve(res)
            }
          })
        })
      },
      async codeImg(row) {
        let res = await this.getHouseShareImgCode('HOUSE_QR_CODE', row.houseId)
        if (res.code === 0) {
          downImgUrl(
            res.data,
            `${row.communityName}${row.buildingName}${row.houseName}`
          )
        }
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        this.$router.push({
          path: '/merchant/device/manage',
          query: {
            id: row.id,
            shopName: row.shopName,
          },
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getHousePublish({ ...this.listQuery, approvalStatus: 1 })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          buildingName: '',
          houseName: '',
          areaCode: '',
          shelfStatus: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }
</style>
