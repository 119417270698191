<template>
  <div class="content">
    <el-page-header :content="'合同详情'" @back="goBack" />
    <div class="main">
      <div class="basic">
        <div class="title">基本信息</div>
        <el-descriptions border class="margin-top" :column="4" size="medium">
          <el-descriptions-item>
            <template slot="label">合同编号</template>
            {{ detail.code }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">签约方式</template>
            <span v-if="detail.contractWay === 1">纸质</span>
            <span v-else>电子</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">合同房源</template>
            {{ detail.buildingName }}-{{ detail.houseName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">状态</template>
            <el-tag v-if="detail.status === 1" type="primary">生效中</el-tag>
            <el-tag v-if="detail.status === 0" type="info">已完结</el-tag>
            <el-tag v-if="detail.status === 2" type="info">已过期</el-tag>
            <el-tag v-if="detail.status === 3" type="warning">退租中</el-tag>
            <el-tag v-if="detail.status === 5" type="info">待生效</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户签约人</template>
            {{ detail.contractPerson }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户签约人电话</template>
            {{ detail.contactPersonPhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">签订时间</template>
            {{ detail.contractDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">合同文件</template>
            <span
              style="color: blue; margin-right: 5px; cursor: pointer"
              @click="previewImg"
            >
              预览
            </span>
            <!-- <el-image
                  placeholder="111"
                  :preview-src-list="srcList"
                  :src="detail.contractImgs"
                  style="width: 50px; height: 50px; margin-top: 10px"
                  title="点击放大预览"
                >
                  <span @click="previewImg">预览</span>
                </el-image> -->
            <span
              style="color: blue; cursor: pointer"
              @click="downloadByBlob(detail.contractImgs, 'name')"
            >
              下载
            </span>
            <!-- <el-image
                  v-if="isShowImg"
                  :preview-src-list="srcList"
                  :src="detail.contractImgs"
                /> -->
            <!-- <el-image :preview-src-list="srcList" :src="contractImgs">
                  预览
                </el-image> -->
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="rent">
        <div class="title">租赁信息</div>
        <el-descriptions border class="margin-top" :column="4" size="medium">
          <el-descriptions-item>
            <template slot="label">承租人</template>
            {{ detail.tenantName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">承租人手机号</template>
            {{ detail.tenantPhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">承租人身份证</template>
            {{ detail.tenantIdcard }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">租赁模式</template>
            {{ detail.billModeName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">租金方式</template>
            {{ detail.rentalWayName }}
          </el-descriptions-item>
          <template v-for="(item, index) in subjectList">
            <el-descriptions-item :key="index">
              <template slot="label">{{ item.subjectName }}</template>
              {{ item.defaultUnitPrice }}{{ item.unitName }}
            </el-descriptions-item>
          </template>
          <el-descriptions-item>
            <template slot="label">开始租赁日期</template>
            {{ detail.startDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结束租赁日期</template>
            {{ detail.endDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">应支付日</template>
            {{ detail.billPayDay }}号
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">可支付日提前</template>
            {{ detail.aheadPayDay }}天
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="rent">
        <div class="title1">入住人信息</div>
        <div v-for="item in renterList" :key="item.id" class="renter">
          <div>
            <span>{{ item.name }}</span>
          </div>
          <div style="margin: 10px 0">
            <span>手机号: {{ item.phone }}</span>
          </div>
          <div>
            <span>身份证号: {{ item.idcard }}</span>
          </div>
        </div>
      </div>

      <div class="rent1">
        <div class="title1">合同期账单</div>
        <div class="bill">
          <div class="item">应收金额: {{ totalDetail.receivableAmount }}元</div>
          <div class="item">已支付费用: {{ totalDetail.hasPayEdAmount }}元</div>
          <div class="item">未支付费用: {{ totalDetail.noPayEdAmount }}元</div>
          <div class="item">
            平台服务费: {{ totalDetail.billServiceAmount }}元
          </div>
          <div class="item">实收金额: {{ totalDetail.realAmount }}元</div>
        </div>
        <el-table
          v-loading="loading"
          border
          :data="billList"
          max-height="400"
          stripe
        >
          <el-table-column
            align="center"
            label="账单号"
            prop="id"
            show-overflow-tooltip
            width="200"
          />
          <el-table-column
            align="center"
            label="应收金额(元)"
            prop="receivableAmount"
            width="120"
          />
          <el-table-column
            align="center"
            label="平台服务费(元)"
            prop="billServiceAmount"
            width="120"
          />
          <el-table-column
            align="center"
            label="实收金额(元)"
            prop="realAmount"
            width="120"
          />
          <el-table-column
            align="center"
            label="账单状态"
            prop="billStatusName"
          />
          <el-table-column align="center" label="账单周期" prop="billCycle" />
          <el-table-column
            align="center"
            label="应支付日"
            prop="billDate"
            width="200"
          />
          <el-table-column align="center" label="逾期情况" prop="overDueInfo" />
          <el-table-column
            align="center"
            label="支付方式"
            prop="paymentWayName"
          />
          <el-table-column
            align="center"
            label="支付状态"
            prop="payStatusName"
          />
          <el-table-column align="center" label="支付时间" prop="realPayTime" />
          <el-table-column
            align="center"
            label="自动提现时间"
            prop="withdrawTime"
            width="120"
          />
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleCheckDetail(scope.row.id, scope.row.billCycle)"
              >
                详情
              </el-button>
              <el-button type="text" @click="handleCheckService(scope.row.id)">
                服务费
              </el-button>
            </template>
          </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
        </el-table>
        <Pagination
          :limit.sync="listQuery.pageSize"
          :page.sync="listQuery.pageNumber"
          :total-count="totalCount"
          @pagination="getBillList"
        />
      </div>

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :showfoot="dailog.showfoot"
        :title="dailog.title"
        :visible="dailog.visible"
        width="900px"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-table border :data="tableDataBill" stripe>
            <el-table-column
              align="center"
              label="费用类型"
              prop="billSubjectName"
            />
            <el-table-column align="center" label="用量" prop="costMeterVal" />
            <el-table-column align="center" label="单价" prop="unitPrice" />
            <!-- 逾期情况，支付状态，支付方式 -->
            <el-table-column
              align="center"
              label="逾期情况"
              prop="overDueInfo"
            />
            <el-table-column
              align="center"
              label="支付方式"
              prop="paymentWayName"
            />
            <el-table-column
              align="center"
              label="支付状态"
              prop="payStatusName"
            />
            <el-table-column
              align="center"
              label="费用生成时间"
              prop="createTime"
              width="200"
            />
            <el-table-column
              align="center"
              label="金额(元)"
              prop="receivableAmount"
            />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
          </el-table>
        </template>
      </Dialog>

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :showfoot="dailog.showfoot"
        :title="dailog.title"
        :visible="dailog.visibleService"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-table border :data="tableDataService" stripe>
            <el-table-column align="center" label="费用类型" prop="name" />
            <el-table-column align="center" label="收费标准" prop="unit" />
            <el-table-column align="center" label="数量" prop="count" />
            <el-table-column
              align="center"
              label="收费金额(元)"
              prop="amount"
            />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
          </el-table>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import {
    contractDetail,
    housePeopleDetail,
    contractBill,
    costTotal,
    findContractBillDetailList,
    billServeFee,
    getShopBillSubject,
  } from '@/api/build/build'
  import Dialog from '@/components/dialog.vue'
  import Pagination from '@/components/pagination.vue'
  import { PaymentWayEnum } from '@/utils/bill'
  import { BILLSUBJECTTYPE } from '@/utils/constant'

  export default {
    name: 'ContractDetail',
    components: { Dialog, Pagination },
    data() {
      return {
        id: '',
        detail: {},
        loading: false,
        totalCount: 0,
        listQuery: {
          contractId: '',
          pageNumber: 1,
          pageSize: 20,
        },
        renterList: [],
        tableData: [],
        billList: [],
        dailog: {
          height: null,
          title: '',
          visible: false,
          visibleService: false,
          width: '600px',
          showfoot: false,
        },
        tableDataBill: [],
        tableDataService: [],
        totalDetail: {},
        srcList: [],
        isShowImg: false,
        contractImgs: '',
        subjectList: [],
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.listQuery.contractId = this.$route.query.id
      console.log(this.id)
      this.getList()
      this.getBillList()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async getBillSubject(shopId) {
        const { code, data } = await getShopBillSubject({ shopId })
        if (code === 0) {
          const basicCostList = Object.keys(BILLSUBJECTTYPE)
            .map((item) => {
              const subject = data.find(
                (v) => v.subjectId === BILLSUBJECTTYPE[item]
              )
              let key = `${item}Price`
              if (
                [BILLSUBJECTTYPE.rental, BILLSUBJECTTYPE.deposit].includes(
                  BILLSUBJECTTYPE[item]
                )
              )
                key = `${item}Amount`
              return {
                ...subject,
                defaultUnitPrice: this.detail[key],
              }
            })
            .filter((item) => !!item.defaultUnitPrice)
          let customCostList = []
          if (this.detail.subjectList) {
            customCostList = this.detail.subjectList.map((item) => {
              const subject = data.find(
                (v) => v.subjectId === item.billSubjectId
              )
              return { ...subject, defaultUnitPrice: item.unitPrice }
            })
          }
          this.subjectList = [...basicCostList, ...customCostList]
        }
      },
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        this.$router.push({ name: 'Contract' })
        this.delVisitedRoute(detailPath)
      },
      previewImg() {
        // this.isShowImg = true
        // this.contractImgs = this.srcList[0]
        if (!this.detail.contractImgs) {
          this.$message({
            type: 'warning',
            message: '当前合同无文件!',
          })
          return
        }
        window.open(this.detail.contractImgs, '_blanck')
      },
      downloadByBlob(url, name) {
        if (!this.detail.contractImgs) {
          this.$message({
            type: 'warning',
            message: '当前合同无文件!',
          })
          return
        }
        let image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
        image.onload = () => {
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, image.width, image.height)
          canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            this.download(url, name)
            // 用完释放URL对象
            URL.revokeObjectURL(url)
          })
        }
      },
      download(href, name) {
        let eleLink = document.createElement('a')
        eleLink.download = name
        eleLink.href = href
        eleLink.click()
        eleLink.remove()
      },
      getList() {
        contractDetail({ id: this.id }).then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.srcList = []
            this.detail = {
              ...res.data,
              startDate: res.data.startDate.slice(0, 10),
              endDate: res.data.endDate.slice(0, 10),
              contractDate: res.data.contractDate.slice(0, 10),
            }
            this.srcList.push(res.data.contractImgs)
            this.getBillSubject(this.detail.shopId)
          }
        })
        housePeopleDetail({ id: this.id }).then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.renterList = res.data
          }
        })
      },
      getBillList() {
        this.loading = true
        contractBill(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.billList = records.map((item) => {
              return {
                ...item,
                paymentWayName: PaymentWayEnum.getLabelByValue(item.paymentWay),
              }
            })
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
        costTotal({ contractId: this.id }).then((res) => {
          if (res.code === 0) this.totalDetail = res.data
        })
      },
      handleCheckDetail(id, billCycle) {
        console.log(id)
        this.dailog.visible = true
        this.dailog.title = `${id}-账单详情`
        const data = {
          contractId: this.id,
          billCycle,
        }
        const currentBill = this.billList.find((item) => item.id == id)
        findContractBillDetailList(data).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.tableDataBill = res.data
            this.tableDataBill.overDueInfo = currentBill.overDueInfo
            this.tableDataBill.paymentWayName = currentBill.paymentWayName
            this.tableDataBill.payStatusName = currentBill.payStatusName
          }
        })
      },
      handleCheckService(id) {
        console.log(id)
        this.dailog.visibleService = true
        this.dailog.title = `${id}-账单平台服务费`
        billServeFee({ id }).then((res) => {
          console.log('billServeFee', res)
          if (res.code === 0) {
            this.tableDataService = res.data
          }
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.dailog.visibleService = false
      },
      handleConfirm() {
        this.dailog.visible = false
        this.dailog.visibleService = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    background-color: #f6f8f9 !important;

    .main {
      .basic {
        margin-bottom: 20px;
        background-color: #fff;
        padding: 10px 0 0 10px;

        .title {
          width: 60px;
          border-bottom: 2px solid #000;
          padding-bottom: 10px;
        }
      }

      .rent,
      .rent1 {
        margin-bottom: 20px;
        background-color: #fff;
        padding: 10px 0 20px 10px;

        .renter {
          margin-top: 20px;
          width: 250px;
          height: 120px;
          border: 1px solid #ccc;
          padding: 25px 0 0 20px;
        }

        .title {
          width: 60px;
          border-bottom: 2px solid #000;
          padding-bottom: 10px;
        }

        .title1 {
          width: 70px;
          border-bottom: 2px solid #000;
          padding-bottom: 10px;
        }

        .title2 {
          width: 90px;
          border-bottom: 2px solid #000;
          padding-bottom: 10px;
        }
      }

      .rent1 {
        margin-bottom: 0px;

        .bill {
          display: flex;
          margin: 20px 0;

          .item {
            margin-right: 30px;
          }
        }
      }
    }

    .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    .vab-footer {
      background-color: pink !important;
    }
  }
</style>
