<template>
  <div class="index-container">
    <el-form label-width="80px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="合同编号">
            <el-input
              v-model="listQuery.code"
              clearable
              placeholder="请输入合同编号"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="请输入楼栋名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="房号">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="请输入房号"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="承租人">
            <el-input
              v-model="listQuery.tenantName"
              clearable
              placeholder="请输入承租人"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 6 }"
          :sm="{ span: 6 }"
          :xl="{ span: 6 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="合同状态">
            <el-select v-model="listQuery.status" placeholder="请选择状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="承租人"
        prop="tenantName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="合同编号"
        prop="code"
        width="200"
      />
      <el-table-column
        align="center"
        label="商户名称"
        prop="contractPerson"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="房号"
        prop="houseName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="状态"
        prop="status"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="primary">生效中</el-tag>
          <el-tag v-if="scope.row.status === 0" type="info">已完结</el-tag>
          <el-tag v-if="scope.row.status === 2" type="info">已过期</el-tag>
          <el-tag v-if="scope.row.status === 3" type="warning">退租中</el-tag>
          <el-tag v-if="scope.row.status === 5" type="info">待生效</el-tag>
          <el-tag v-if="scope.row.status === 6" type="warning">待签署</el-tag>
          <el-tag v-if="scope.row.status === 8" type="info">已撤销</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="签约方式"
        prop="createTime"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.contractWay === 1">纸质</span>
          <span v-if="scope.row.contractWay === 2">电子</span>
          <!-- <span v-if="scope.row.contractWay === 0">已完结</span> -->
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="签约时间"
        prop="contractDateStr"
        width="200"
      />
      <el-table-column
        align="center"
        label="账单总周期"
        prop="totalBillCycle"
        width="100"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row.id)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <el-drawer
      :before-close="handleClose"
      :direction="direction"
      :size="'50%'"
      title="楼栋信息详情"
      :visible.sync="drawer"
    >
      <div class="content">
        <el-descriptions
          border
          class="margin-top"
          :column="2"
          :size="size"
          title=""
        >
          <el-descriptions-item>
            <template slot="label">合同编号</template>
            {{ drawerData.code }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ drawerData.contractPerson }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">楼栋名称</template>
            {{ drawerData.buildingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">房源名称</template>
            {{ drawerData.houseName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">状态</template>
            <span v-if="drawerData.status === 1">生效中</span>
            <span v-if="drawerData.status === 0">已完结</span>
            <span v-if="drawerData.status === 2">已过期</span>
            <span v-if="drawerData.status === 3">退租中</span>
            <span v-if="drawerData.status === 5">待生效</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">签约方式</template>
            <span v-if="drawerData.contractWay === 1">纸质</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">签约时间</template>
            {{ drawerData.contractDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">起租时间</template>
            {{ drawerData.startDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退组日期</template>
            {{ drawerData.endDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">租金方式</template>
            <span v-if="drawerData.rentalWay === 1">月租</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">租金次数</template>
            {{ drawerData.rentalNum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">押金次数</template>
            {{ drawerData.depositNum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">租金金额</template>
            {{ drawerData.propertyPrice }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">押金金额</template>
            {{ drawerData.depositAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单总周期</template>
            {{ drawerData.totalBillCycle }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{ drawerData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">承租人姓名</template>
            {{ drawerData.tenantName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">承租人手机</template>
            {{ drawerData.tenantPhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{ drawerData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">修改时间</template>
            {{ drawerData.updateTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">补充</template>
            {{ drawerData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">合同扫描件</template>
            {{ drawerData.contractImgs }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import { contractList } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'Contract',
    components: {
      Pagination,
    },
    data() {
      return {
        options: [
          { value: 5, label: '待生效' },
          { value: 6, label: '待签署' },
          { value: 1, label: '生效中' },
          { value: 3, label: '退租中' },
          { value: 0, label: '已完结' },
          { value: 8, label: '已撤销' },
          { value: 2, label: '已过期' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          code: '',
          shopName: '',
          buildingName: '',
          houseName: '',
          memberName: '',
          status: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        contractList(this.listQuery).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handleCheckDetail(id) {
        console.log(id)
        this.$router.push({
          path: '/admin/house/contract/detail',
          query: { id },
        })
      },
      handleClose() {
        this.drawer = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
    }
  }
</style>
