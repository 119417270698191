<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="dailog.showfoot"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCancel"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        :inline="true"
        label-width="80px"
        :model="listQuery"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item label="方式" prop="keyType">
          <el-select
            v-model="listQuery.keyType"
            placeholder="请选择方式"
            style="width: 400px"
          >
            <el-option
              v-for="item in keyTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="validityType">
          <el-select
            v-model="listQuery.validityType"
            placeholder="请选择类型"
            style="width: 400px"
          >
            <el-option
              v-for="item in validityTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="listQuery.validityType === 3"
          label="有效"
          prop="rangeTime"
        >
          <el-date-picker
            v-model="listQuery.rangeTime"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            style="width: 400px"
            type="datetimerange"
          />
        </el-form-item>
        <el-form-item v-if="listQuery.validityType === 2" label="有效">
          永久
        </el-form-item>
        <el-form-item v-if="listQuery.keyType === 3" label="机制">
          <el-input
            readonly=""
            style="width: 400px"
            type="textarea"
            value="保存后，点击该秘钥的[匹配IC卡]， 手持IC卡点亮密码锁后刷卡完成绑定即可使用"
          />
        </el-form-item>
        <el-form-item v-if="listQuery.keyType === 4" label="机制">
          <el-input
            readonly=""
            style="width: 400px"
            type="textarea"
            value="保存后，点击该条秘钥的[匹配指纹]， 点亮密码锁后按提示输入即可使用。"
          />
        </el-form-item>
        <el-form-item v-if="listQuery.keyType === 2" label="机制" prop="random">
          <el-select
            v-model="listQuery.random"
            placeholder="请选择机制"
            style="width: 400px"
            @change="handleChangeRandom"
          >
            <el-option
              v-for="item in randomOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="listQuery.keyType === 2"
          label="密码"
          prop="content"
        >
          <el-input
            v-model="listQuery.content"
            clearable
            placeholder="6-8位数字"
            show-password
            style="width: 400px"
            type="password"
          >
            <el-button
              v-if="listQuery.random"
              slot="append"
              icon="el-icon-refresh"
              @click="handleChangeRandom"
            />
          </el-input>
        </el-form-item>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import dayjs from 'dayjs'

  export default {
    components: {
      Dialog,
    },
    props: {
      validityTypeOptions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        keyTypeOptions: [
          {
            label: '密码钥匙',
            value: 2,
          },
        ],
        rules: {
          keyType: [
            { required: true, message: '请选择方式', trigger: 'change' },
          ],
          validityType: [
            {
              required: true,
              message: '请选择类型',
              trigger: 'change',
            },
          ],
          rangeTime: [
            {
              required: true,
              message: '请选择日期',
              trigger: 'change',
            },
          ],
          random: [
            {
              required: true,
              message: '请选择机制',
              trigger: 'change',
            },
          ],
          content: [
            { required: true, message: '请输入密码', trigger: 'change' },
          ],
        },
        dailog: {
          height: 400,
          title: '钥匙设置',
          visible: false,
          showfoot: true,
          visibleAddOrEdit: false,
          width: '520px',
          top: '5vh',
        },
        listQuery: {
          keyType: undefined,
          validityType: undefined,
          createTime: undefined,
          endDate: undefined,
          rangeTime: null,
          random: undefined,
          content: undefined,
        },
        randomOptions: [
          {
            label: '随机',
            value: 1,
          },
          {
            label: '自定义',
            value: 0,
          },
        ],
      }
    },
    methods: {
      generateRandomNumber(minLength, maxLength) {
        const min = Math.pow(10, minLength - 1) // 10^(minLength-1) 是最小值
        const max = Math.pow(10, maxLength) - 1 // 10^maxLength - 1 是最大值
        return Math.floor(Math.random() * (max - min + 1)) + min
      },
      show() {
        this.dailog.visible = true
      },
      handleCancel() {
        this.handleReset()
        this.dailog.visible = false
      },
      handleReset() {
        this.$refs['form'].resetFields()
      },
      handleChangeRandom(val) {
        if (val) {
          this.listQuery.content = this.generateRandomNumber(6, 8)
        } else {
          this.listQuery.content = undefined
        }
      },
      handleConfirm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              ...this.listQuery,
              createTime:
                this.listQuery?.rangeTime?.length > 1
                  ? dayjs(this.listQuery.rangeTime[0]).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : undefined,
              endDate:
                this.listQuery?.rangeTime?.length > 1
                  ? dayjs(this.listQuery.rangeTime[1]).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : undefined,
            }
            delete params.rangeTime
            this.$emit('handleSubmit', params)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    color: #8a96a8;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
</style>
