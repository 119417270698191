<template>
  <div class="index-container">
    <el-form label-width="100px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="请输入楼栋名称"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="房间号">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="请输入房间号"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="地区">
            <el-cascader
              v-model="areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="状态">
            <el-select
              v-model="listQuery.status"
              clearable
              placeholder="请选择状态"
              style="width: 100%"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-form-item label="">
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-button
      size="mini"
      style="margin-bottom: 20px"
      type="danger"
      @click="handleDelete"
    >
      删除
    </el-button> -->
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="省/市/区" width="200">
        <template slot-scope="scope">
          {{ scope.row.province }}/{{ scope.row.city }}/{{ scope.row.district }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="楼栋名称" prop="buildingName" />
      <el-table-column align="center" label="房间号" prop="name" />
      <el-table-column align="center" label="面积(平方米)" prop="area" />
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">空房可租</el-tag>
          <el-tag v-if="scope.row.status === 2" type="danger">
            空房不可租
          </el-tag>
          <el-tag v-if="scope.row.status === 3">在租中</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button> -->
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-descriptions border class="margin-top" :column="2" size="medium">
          <el-descriptions-item>
            <template slot="label">房源ID</template>
            {{ drawerData.id }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">房间号</template>
            {{ drawerData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ drawerData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">楼栋名称</template>
            {{ drawerData.buildingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">面积</template>
            {{ drawerData.area }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">朝向</template>
            {{ drawerData.toward }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">房间数量</template>
            {{ drawerData.roomNum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">厅数量</template>
            {{ drawerData.hallNum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">卫生间数量</template>
            {{ drawerData.washroomNum }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label">可居住人数</template>
            {{ drawerData.peopleNum }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label">楼层</template>
            {{ drawerData.floor }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">状态</template>
            <el-tag v-if="drawerData.status === 1" type="success">
              空房可租
            </el-tag>
            <el-tag v-if="drawerData.status === 2" type="info">
              空房不可租
            </el-tag>
            <el-tag v-if="drawerData.status === 3" type="danger">在租中</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">业务状态</template>
            <!-- {{ drawerData.name }} -->
            无
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">省</template>
            {{ drawerData.province }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">市</template>
            {{ drawerData.city }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">区</template>
            {{ drawerData.district }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算账户</template>
            {{ drawerData.openBank }} {{ drawerData.bankCardNo }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label">开户支行</template>
            {{ drawerData.openSubBank }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label">开户账号</template>
            {{ drawerData.bankCardNo }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label">详细地址</template>
            {{ drawerData.address }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{ drawerData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">修改时间</template>
            {{ drawerData.updateTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { buildList, deleteBuild } from '@/api/build/build'
  import { regionList } from '@/api/system/user'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'Info',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          shopName: '',
          buildingName: '',
          name: '',
          status: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
        areaCode: '',
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        dailog: {
          height: null,
          title: '',
          visible: false,
          visibleService: false,
          width: '700px',
          showfoot: false,
        },
        // 1-空房可租 2-空房不可租 3-在租中
        statusList: [
          { label: '在租中', value: 3 },
          { label: '空房可租', value: 1 },
          { label: '空房不可租', value: 2 },
        ],
      }
    },
    mounted() {
      const { shopName, buildingName } = this.$route.query
      if (shopName || buildingName) {
        this.listQuery.shopName = shopName
        this.listQuery.buildingName = buildingName
      }
      this.getList()
      this.getRegiList()
    },
    methods: {
      handleCloseDialog() {
        this.dailog.visible = false
      },
      handleChange(val) {
        console.log(val)
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
      },
      // 列表
      getList() {
        this.loading = true
        buildList(this.listQuery).then((res) => {
          // console.log(res)
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      getRegiList() {
        regionList().then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.areaList = res.data
          }
        })
      },
      handleCheckDetail(row) {
        console.log(row)
        this.dailog.visible = true
        this.dailog.title = '房源详情'
        this.drawerData = row
      },
      // 删除
      handleDelete() {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteBuild({ ids: this.ids }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      selectRow(selection) {
        console.log('单选===', selection)
        // console.log('单选===', row)
        let ids = []
        selection.forEach((item) => {
          ids.push(item.id)
        })
        this.ids = ids.join(',')
        console.log(this.ids)
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      handleClose() {
        this.drawer = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .content {
      padding: 0 20px;
    }
    :deep() {
      .el-table--border .el-table__cell:first-child .cell {
        padding-left: 0;
      }
      .el-table th.el-table__cell > .cell {
        padding-right: 0;
      }
      .el-table .cell {
        padding: 0;
      }
    }
    ::v-deep() .el-descriptions--small.is-bordered .el-descriptions-item__cell {
      font-size: 15px !important;
    }
  }
</style>
