<template>
  <div>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCancel"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <div class="content-header">
          说明：密码设定时是整点生效，且生效后必需在开始时间24小时内使用一次，否则将失效。
          <el-button type="primary" @click="handleAdd">新增钥匙</el-button>
        </div>
        <el-table border :data="tableData" stripe>
          <el-table-column align="center" label="场景" prop="name" />
          <el-table-column align="center" label="方式" prop="keyType">
            <template slot-scope="scope">
              {{
                keyTypeOptions.find((item) => +item.value === scope.row.keyType)
                  .label
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="类型" prop="validityType">
            <template slot-scope="scope">
              {{
                validityTypeOptions.find(
                  (item) => +item.value === scope.row.validityType
                ).label
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="有效周期"
            prop="shopName"
            width="300"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.validityType === 2">永久</span>
              <span v-if="scope.row.validityType === 3">
                {{ scope.row.createTime }}至{{ scope.row.endDate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="密码信息" prop="content" />
          <!-- <el-table-column align="center" label="在用用户" prop="userCount" />
          <el-table-column
            align="center"
            label="历史用户"
            prop="historyUserCount"
          /> -->
          <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" @click="handleUpdate(scope.row)">
                修改密码
              </el-button>
              <el-button type="text" @click="handleAuthorizedUser(scope.row)">
                授权用户
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          :limit.sync="pageSize"
          :page.sync="pageNumber"
          :total-count="totalCount"
          @pagination="fetchData"
        />
      </template>
    </Dialog>
    <addKeySettingsVue
      ref="addKeySettingsRef"
      :validity-type-options="validityTypeOptions"
      @handleSubmit="handleSubmit"
    />
    <updatePasswordVue
      ref="updatePasswordRef"
      @handleSubmitPassword="handleSubmitPassword"
    />
    <authorizedUserVue
      ref="authorizedUserRef"
      @handleSubmitAuthorizedUser="handleSubmitAuthorizedUser"
    />
  </div>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import addKeySettingsVue from './add-key-settings.vue'
  import {
    addLockKey,
    getLockKey,
    deleteLockKey,
    updateLockKey,
    addLockMemberRelation,
  } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'
  import updatePasswordVue from './update-password.vue'
  import authorizedUserVue from './authorized-user.vue'
  export default {
    components: {
      Dialog,
      addKeySettingsVue,
      Pagination,
      updatePasswordVue,
      authorizedUserVue,
    },
    data() {
      return {
        pageSize: 10,
        pageNumber: 1,
        totalCount: 0,
        records: {},
        dailog: {
          height: 400,
          title: '钥匙设置',
          visible: false,
          showfoot: false,
          visibleAddOrEdit: false,
          width: '1000px',
          top: '5vh',
        },
        tableData: [],
        keyTypeOptions: [
          {
            label: '电子钥匙',
            value: 1,
          },
          {
            label: '密码钥匙',
            value: 2,
          },
          {
            label: 'ic卡',
            value: 3,
          },
          {
            label: '指纹',
            value: 4,
          },
          {
            label: '人脸',
            value: 5,
          },
        ],
        validityTypeOptions: [
          // {
          //   label: '一次性',
          //   value: 1,
          // },
          {
            label: '永久',
            value: 2,
          },
          {
            label: '时间范围',
            value: 3,
          },
        ],
        randomOptions: [
          {
            label: '随机',
            value: true,
          },
          {
            label: '自定义',
            value: false,
          },
        ],
      }
    },
    methods: {
      handleDelete(records) {
        this.$confirm('是否删除该钥匙设置?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteLockKey(records.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.handleSearch()
          })
        })
      },
      handleAuthorizedUser(records) {
        this.$refs.authorizedUserRef.show(records)
      },
      handleAdd() {
        this.$refs.addKeySettingsRef.show()
      },
      handleUpdate(records) {
        this.$refs.updatePasswordRef.show(records)
      },
      handleShow(records) {
        this.dailog.visible = true
        this.records = records
        this.handleSearch()
      },
      handleCancel() {
        this.dailog.visible = false
      },
      handleConfirm() {},
      handleSearch() {
        getLockKey({
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          houseId: this.records.houseId,
        }).then((res) => {
          this.tableData = res.data.records
          this.totalCount = +res.data.total
        })
      },
      handleSubmit(val) {
        let params = {
          ...val,
          houseId: this.records.houseId,
          shopId: this.records.shopId,
          deviceId: this.records.id,
          status: 1,
        }
        addLockKey(params).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '添加成功!',
            })
            this.$refs.addKeySettingsRef.handleCancel()
            this.$refs.addKeySettingsRef.handleReset()
            this.handleSearch()
          }
        })
      },
      handleSubmitPassword(val) {
        let params = {
          ...val,
          houseId: this.records.houseId,
          shopId: this.records.shopId,
          deviceId: this.records.id,
        }
        updateLockKey(params).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '修改成功!',
            })
            this.$refs.updatePasswordRef.handleCancel()
            this.$refs.updatePasswordRef.handleReset()
            this.handleSearch()
          }
        })
      },
      handleSubmitAuthorizedUser(val) {
        addLockMemberRelation({ ...val }).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '添加成功!',
            })
            this.$refs.authorizedUserRef.handleCancel()
            this.$refs.authorizedUserRef.handleReset()
            this.handleSearch()
          }
        })
      },
      fetchData(val) {
        this.pageSize = val.limit
        this.pageNumber = val.page + 1
        this.handleSearch()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #8a96a8;
    text-align: center;
  }
</style>
