var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "详情",
        visible: _vm.squareVisible,
        width: "880px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { "label-width": "100px", model: _vm.formData, size: "small" },
        },
        [
          _c("div", { staticClass: "card-title" }, [_vm._v("房源基本信息")]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { border: "", column: 2, size: "medium" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("房间名称")]),
                  _vm._v(" " + _vm._s(_vm.formData.publishName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("所属小区")]),
                  _vm._v(" " + _vm._s(_vm.formData.communityName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("商户名称")]),
                  _vm._v(" " + _vm._s(_vm.formData.shopName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("房源状态")]),
                  _vm.formData.houseStatus == 1
                    ? _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v(" 空房可租 "),
                      ])
                    : _vm._e(),
                  _vm.formData.houseStatus == 3
                    ? _c("el-tag", [_vm._v("在租中")])
                    : _vm._e(),
                  _vm.formData.houseStatus == 2
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v(" 空房不可租 "),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("房源ID")]),
                  _vm._v(" " + _vm._s(_vm.formData.houseId) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("房型")]),
                  _vm._v(" " + _vm._s(_vm.formData.houseShape) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("业主联系人1")]),
                  _vm._v(" " + _vm._s(_vm.formData.phone) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("业主联系人2")]),
                  _vm._v(" " + _vm._s(_vm.formData.contactPhone || "") + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("所属地区")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formData.province
                          ? _vm.formData.province
                          : "" + _vm.formData.city + _vm.formData.district
                      ) +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("所在楼层")]),
                  _vm._v(" " + _vm._s(_vm.formData.floor) + "层 "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("详细地区")]),
                  _vm._v(" " + _vm._s(_vm.formData.address) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("租客广场")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formData.publishStatus == 1 ? "已添加" : "未添加"
                      ) +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("面积")]),
                  _vm._v(" " + _vm._s(_vm.formData.area) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("租金")]),
                  _vm._v(" " + _vm._s(_vm.formData.publishRentPrice) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("朝向")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.formData.toward ? _vm.formData.toward : "") +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("房源类型")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.houseTypeFilter(_vm.formData.houseType) || "--"
                      ) +
                      " "
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-row", [_c("el-divider")], 1),
              _vm.formData.housePhotoList?.length > 0
                ? _c("div", { staticClass: "card-title" }, [
                    _vm._v(" 房源照片 "),
                  ])
                : _vm._e(),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { border: "", column: 1, size: "medium" },
                },
                [
                  _vm._l(_vm.formData.housePhotoList, function (item, idx) {
                    return _c(
                      "el-descriptions-item",
                      { key: idx },
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v(_vm._s(item.placeTag)),
                        ]),
                        _c("img", {
                          staticStyle: { width: "300px", height: "300px" },
                          attrs: { src: item.url },
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("备注")]),
                      _vm._v(" " + _vm._s(_vm.formData.remark) + " "),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }