<template>
  <div class="index-container meter-reading">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="小区名称">
            <el-input
              v-model="listQuery.communityName"
              clearable
              placeholder="小区名称"
            />
          </el-form-item>
          <el-form-item label="地区">
            <el-cascader
              v-model="listQuery.areaCode"
              clearable
              :options="areaList"
              placeholder="请选择地区"
              :props="areaProps"
              style="width: 100%"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <vab-query-form-top-panel>
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加小区
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form-top-panel>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="小区ID" prop="id" />
      <el-table-column align="center" label="小区名称" prop="name" />
      <el-table-column align="center" label="所属地区" prop="productName">
        <template slot-scope="scope">
          {{ scope.row.province }}/{{ scope.row.city }}/{{ scope.row.district }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="详细地址" prop="address" />
      <el-table-column align="center" label="关联房东" prop="shopCount">
        <template slot-scope="scope">
          <el-button type="text" @click="openShop(scope.row.relevanceShops)">
            {{
              scope.row.shopCount > 0 ? scope.row.shopCount + ' 位' : '无关联'
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.row.id)">
            删除
          </el-button>
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-table border :data="shopData" stripe>
          <el-table-column align="center" label="商户名" prop="shopName" />
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleShopDetail(scope.row.shopId)"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
      </template>
    </Dialog>
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visibleAddOrEdit"
      :title="dailog.title"
      :top="dailog.top"
      :visible="dailog.visibleAddOrEdit"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="120px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="小区名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                placeholder="请输入小区名称"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item label="所属地区" prop="provinceCode">
              <CommunityRegionPicker
                :default-text="getRegionText('address')"
                :enable="enablePicker"
                placeholder="请选择省/市/区/街道"
                prop="address"
                style="width: 90%"
                @change="handleRegionChange"
              />
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-select
                v-model="formData.address"
                :disabled="isDisabled"
                filterable
                :loading="addressLoading"
                placeholder="请输入关键词搜索小区地址"
                remote
                :remote-method="remoteMethod"
                reserve-keyword
                style="width: 90%"
                value-key="id"
                @change="changeAddress"
              >
                <el-option
                  v-for="(item, index) in addressOptions"
                  :key="index"
                  :label="item.name"
                  :value="item"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; font-size: 8px; color: #8492a6">
                    {{
                      item.address.length > 0
                        ? item.address.length > 130
                          ? item.district
                          : item.address
                        : item.district
                    }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div
                id="mapContainerbuilding"
                style="width: 90%; height: 260px"
              ></div>
            </el-form-item>
            <el-form-item label="小区图片">
              <JMUpload v-model="formData.img" :multiple="false" :number="1" />
              <span class="png-tip">
                图片尺寸: 660x330PX，或者宽高比2:1， 图片大小请限制在10MB内
              </span>
            </el-form-item>
            <el-form-item label="小区简介" prop="description">
              <el-input
                v-model="formData.description"
                clearable
                placeholder="请输入小区简介最多可输入58字"
                :rows="4"
                style="width: 90%"
                type="textarea"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import CommunityRegionPicker from './components/CommunityRegionPicker'
  import JMUpload from '@/components/jm-upload'
  import axios from 'axios'
  import { GD_API_WEB_SERVER_KEY, GD_HTTPS_URL } from '@/config'
  import { regionList } from '@/api/system/user'
  import {
    getCommunityListApi,
    deleteCommunityApi,
    addCommunityApi,
    editCommunityApi,
  } from '@/api/build/community'
  import { loadMap } from '@/utils/t-map'
  export default {
    name: 'CommunityManagement',
    components: {
      Pagination,
      Dialog,
      CommunityRegionPicker,
      JMUpload,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      var checkAddressNumber = (rule, value, callback) => {
        if (value > 50) {
          callback(new Error('地址不能超过50个字符!'))
        } else {
          callback()
        }
      }
      // var checkDescNumber = (rule, value, callback) => {
      //   if (value > 58) {
      //     callback(new Error('地址不能超过58个字符!'))
      //   } else {
      //     callback()
      //   }
      // }
      return {
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        formData: {
          name: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          district: '',
          districtCode: '',
          street: '',
          streetCode: '',
          address: '',
          description: '',
          longitude: '',
          latitude: '',
          photos: [],
          img: '',
        },
        defaultImg:
          'https://files.zss-rental-service.zushengshi.com/platform/20220729/defaultPlotOfTheCommunity.png',
        gdLongitudeAndLatitude: {
          longitude: 0,
          latitude: 0,
        },
        isDisabled: true,
        addressOptions: [],
        addressLoading: false,
        rules: {
          name: [
            { required: true, message: '请输入小区名称', trigger: 'blur' },
          ],
          address: [
            { required: true, message: '详细地址不能为空', trigger: 'blur' },
            { validator: checkAddressNumber, trigger: 'change' },
          ],
          provinceCode: [
            { required: true, message: '请选择省市区', trigger: 'change' },
          ],
        },
        shopData: [],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          areaCode: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          streetCode: '',
          communityName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [],
        fold: false,
        dailog: {
          height: 0,
          title: '',
          visible: false,
          showfoot: false,
          visibleAddOrEdit: false,
          width: '0px',
          top: '5vh',
        },
        enablePicker: true,
        map: null,
        zoom: 15,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
      this.getRegiList()
    },
    methods: {
      async initAMap() {
        await loadMap()
        const latitude = this.gdLongitudeAndLatitude.latitude || 39.98412
        const longitude = this.gdLongitudeAndLatitude.longitude || 116.307484
        const center = new window.TMap.LatLng(latitude, longitude)
        if (!this.map) {
          this.map = new window.TMap.Map(
            document.getElementById('mapContainerbuilding'),
            {
              // 设置地图中心点坐标
              center,
              // 设置地图缩放级别
              zoom: 14,
            }
          )
        } else {
          this.map.setCenter(center)
        }
        const marker = new window.TMap.MultiMarker({
          map: this.map,
          styles: {
            // 点标记样式
            marker: new window.TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 }, // 描点位置
            }),
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: 'marker',
            },
          ],
        })
        const geocoder = new window.TMap.service.Geocoder()
        this.map.on('dragend', () => {
          const mapCenter = this.map.getCenter()
          const lat = mapCenter.getLat().toFixed(6)
          const lng = mapCenter.getLng().toFixed(6)
          const newCenter = new window.TMap.LatLng(lat, lng)
          if (marker) {
            const markerData = marker.getGeometryById('marker')
            Object.assign(markerData, {
              position: newCenter,
            })
            marker.updateGeometries([markerData])
            console.log('dragend', lat, lng)
            geocoder.getAddress({ location: newCenter }).then((result) => {
              console.log(result.result)
              this.formData.address =
                result.result.formatted_addresses.recommend
              this.formData.latitude = lat
              this.formData.longitude = lng
            })
          }
        })
      },
      handleChange(val) {
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
        this.listQuery.streetCode = val[3]
      },
      getRegiList() {
        regionList().then((res) => {
          if (res.code === 0) {
            this.areaList = res.data
            console.log(this.areaList)
          }
        })
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      fetchData() {
        this.loading = true
        getCommunityListApi(this.listQuery)
          .then((res) => {
            if (res.code === 0) {
              const { records, total } = res.data
              this.tableData = records
              this.totalCount = +total
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      openShop(row) {
        this.dailog.height = 400
        this.dailog.width = '500px'
        this.dailog.visible = true
        this.dailog.title = '关联房东'
        this.$nextTick(() => {
          this.shopData = row
        })
      },
      handleCloseDialog() {
        if (this.dailog.title === '关联房东') {
          this.dailog.visible = false
          this.shopData = []
        } else {
          this.dailog.visibleAddOrEdit = false
          this.gdLongitudeAndLatitude = {
            longitude: 0,
            latitude: 0,
          }
          this.formData = {}
          this.addressOptions = []
        }
      },
      //查看商户详情
      handleShopDetail(shopId) {
        this.$nextTick(() => {
          this.$router.push({
            path: '/admin/merchant/detail',
            query: { id: shopId },
          })
        })
      },
      handleDelete(ids) {
        this.$confirm('是否删除该小区?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteCommunityApi({ ids: ids }).then((res) => {
            this.commonMessage(res)
          })
        })
      },
      handleAdd() {
        this.dailog.visibleAddOrEdit = true
        this.dailog.title = '新增小区'
        this.dailog.height = 500
        this.dailog.width = '700px'
        this.initAMap()
      },
      handleEdit(row) {
        this.dailog.visibleAddOrEdit = true
        this.dailog.title = '编辑小区'
        this.dailog.height = 500
        this.dailog.width = '700px'
        this.addressOptions = []
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
          this.formData.img =
            this.formData.photos !== null && this.formData.photos.length > 0
              ? this.formData.photos[0].photo
              : undefined
          this.isDisabled = false
          this.remoteMethod()
        })
      },
      handleRegionChange(e) {
        let { value } = e
        this.formData = Object.assign({}, this.formData, {
          province: value.province.name,
          provinceCode: value.province.code,
          city: value.city.name,
          cityCode: value.city.code,
          district: value.district.name,
          districtCode: value.district.code,
          street: value.street.name,
          streetCode: value.street.code,
          address: value.street.name,
        })
        this.isDisabled = false
        this.remoteMethod()
        setTimeout(() => {
          this.$refs.formData.validateField('provinceCode')
        }, 0)
      },
      getRegionText() {
        let { formData } = this
        return (
          (formData.province ? formData.province : '') +
          (formData.city ? ` / ${formData.city}` : '') +
          (formData.district ? ` / ${formData.district}` : '') +
          (formData.street ? ` / ${formData.street}` : '')
        )
      },
      //远程搜索位置
      remoteMethod(keywords = '') {
        this.addressOptions = []
        keywords =
          this.formData.province +
          this.formData.city +
          this.formData.district +
          this.formData.street +
          keywords
        this.addressLoading = true
        let url =
          GD_HTTPS_URL +
          '/v3/assistant/inputtips?output=json&city=' +
          this.formData.city +
          '&citylimit=ture&keywords=' +
          keywords +
          '&key=' +
          GD_API_WEB_SERVER_KEY
        axios
          .get(url)
          .then((response) => {
            this.addressOptions = response.data.tips
            //显示地图 位置标记
            if (this.addressOptions.length > 0) {
              this.showAddressFlog(this.addressOptions[0].location)
            }
            this.addressLoading = false
          })
          .catch(() => {
            this.addressLoading = false
          })
      },
      //选中搜索地址
      changeAddress(val) {
        let area =
          this.formData.province + this.formData.city + this.formData.district
        if (area.indexOf(val.district) === -1) {
          this.formData = Object.assign({}, this.formData, {
            province: '',
            provinceCode: '',
            city: '',
            cityCode: '',
            district: '',
            districtCode: '',
            street: '',
            streetCode: '',
          })
        }
        this.formData.address = val.district + this.formData.street + val.name
        this.showAddressFlog(val.location)
      },
      //显示地图 经纬度标记
      showAddressFlog(location) {
        const data = location.split(',')
        this.formData.longitude = data[0]
        this.formData.latitude = data[1]
        this.gdLongitudeAndLatitude = {
          longitude: this.formData.longitude,
          latitude: this.formData.latitude,
        }
        this.initAMap()
      },
      //确定新增/修改
      handleConfirm() {
        var img =
          this.formData.img === undefined || this.formData.img === ''
            ? this.defaultImg
            : this.formData.img
        this.formData.photos = [{ photo: img }]
        this.formData.longitude = this.gdLongitudeAndLatitude.longitude
        this.formData.latitude = this.gdLongitudeAndLatitude.latitude
        this.$refs.formData.validate((valid) => {
          if (valid) {
            if (this.dailog.title === '编辑小区') {
              editCommunityApi(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
                this.fetchData()
              })
            } else {
              addCommunityApi(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
                this.fetchData()
              })
            }
          }
        })
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.fetchData()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          areaCode: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          name: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }

  .png-tip {
    font-size: 12px;
    font-weight: 400;
    line-height: 0;
    color: #ccc;
    text-align: left;
    letter-spacing: 0;
  }
</style>
