<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="dailog.showfoot"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCancel"
  >
    <template #content>
      <el-form
        ref="form"
        :inline="true"
        label-width="100px"
        :model="listQuery"
        @submit.native.prevent
      >
        <!-- <div>
          <el-form-item label="房源：" prop="random">
            {{ listQuery.houseName ? listQuery.houseName : '--' }}
          </el-form-item>
        </div> -->
        <!-- <el-form-item label="设备：" prop="random">
          {{ listQuery.deviceName ? listQuery.deviceName : '--' }}
        </el-form-item> -->
        <el-form-item label="设备编号：" prop="random">
          {{ listQuery.deviceNo ? listQuery.deviceNo : '--' }}
        </el-form-item>
        <el-form-item label="品牌名称：" prop="random">
          {{ listQuery.brandName ? listQuery.brandName : '--' }}
        </el-form-item>
        <el-form-item label="设备型号：" prop="random">
          {{ listQuery.modelName ? listQuery.modelName : '--' }}
        </el-form-item>
        <el-form-item label="设备电量：" prop="random">
          {{ listQuery.surplusNum ? listQuery.surplusNum : '--' }}
        </el-form-item>
        <div>
          <el-form-item label="" label-width="0" prop="random">
            <el-date-picker
              v-model="timeRanges"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              @change="handleSearch"
            />
          </el-form-item>
        </div>
      </el-form>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="操作人" prop="memberName" />
        <el-table-column align="center" label="身份" prop="keyType">
          <template slot-scope="scope">
            {{
              memberTypeOptions.find(
                (item) => +item.value === scope.row.memberType
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="场景" prop="keyName" />
        <el-table-column
          align="center"
          label="开锁方式"
          prop="shopName"
          width="300"
        >
          <template slot-scope="scope">
            {{
              keyTypeOptions.find((item) => +item.value === scope.row.keyType)
                .label
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间" prop="createTime" />
        <el-table-column align="center" label="是否成功" prop="lockStatus">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lockStatus === 1" type="success">
              成功
            </el-tag>
            <el-tag v-else type="danger">失败</el-tag>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="pageSize"
        :page.sync="pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import dayjs from 'dayjs'
  import { getOpenLockRecord } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'

  export default {
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        appName: process.env.VUE_APP_BASE_TITLE,
        loading: false,
        dailog: {
          height: 600,
          title: '查看开锁记录',
          visible: false,
          showfoot: false,
          visibleAddOrEdit: false,
          width: '800px',
          top: '5vh',
        },
        listQuery: {},
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        tableData: [],
        memberTypeOptions: [
          {
            label: '租客',
            value: 1,
          },
          {
            label: '业主',
            value: 2,
          },
          {
            label: '访客',
            value: 3,
          },
          {
            label: process.env.VUE_APP_BASE_TITLE + '运营',
            value: 4,
          },
        ],
        keyTypeOptions: [
          {
            label: '电子钥匙',
            value: 1,
          },
          {
            label: '密码钥匙',
            value: 2,
          },
          {
            label: 'ic卡',
            value: 3,
          },
          {
            label: '指纹',
            value: 4,
          },
          {
            label: '人脸',
            value: 5,
          },
          {
            label: '远程开锁',
            value: 6,
          },
        ],
        timeRanges: null,
      }
    },
    methods: {
      show(records) {
        this.dailog.visible = true
        this.pageNumber = 1
        this.pageSize = 10
        this.listQuery = records
        this.handleSearch()
      },
      handleCancel() {
        this.handleReset()
        this.dailog.visible = false
      },
      handleReset() {
        this.timeRanges = null
      },
      handleSearch() {
        this.loading = true
        getOpenLockRecord({
          houseId: this.listQuery.houseId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          startDate: this.timeRanges
            ? dayjs(this.timeRanges[0]).format('YYYY-MM-DD')
            : undefined,
          endDate: this.timeRanges
            ? dayjs(this.timeRanges[1]).format('YYYY-MM-DD')
            : undefined,
        })
          .then((res) => {
            this.tableData = res.data.records
            this.totalCount = +res.data.total
          })
          .finally(() => {
            this.loading = false
          })
      },
      fetchData(val) {
        this.pageSize = val.limit
        this.pageNumber = val.page + 1
        this.handleSearch()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #8a96a8;
    text-align: center;
  }
</style>
