var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container meter-reading" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "80px",
                    model: _vm.listQuery,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "商户名称" },
                        model: {
                          value: _vm.listQuery.shopName,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "shopName", $$v)
                          },
                          expression: "listQuery.shopName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "楼栋名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "楼栋名称" },
                        model: {
                          value: _vm.listQuery.buildingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "buildingName", $$v)
                          },
                          expression: "listQuery.buildingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "房间号" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "房间号" },
                        model: {
                          value: _vm.listQuery.houseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "houseName", $$v)
                          },
                          expression: "listQuery.houseName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          options: _vm.areaList,
                          placeholder: "请选择地区",
                          props: _vm.areaProps,
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.listQuery.areaCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "areaCode", $$v)
                          },
                          expression: "listQuery.areaCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上架状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.listQuery.shelfStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "shelfStatus", $$v)
                            },
                            expression: "listQuery.shelfStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未上架", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "已上架", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetQuery },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vab-query-form-top-panel",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-plus",
                        "native-type": "submit",
                        type: "primary",
                      },
                      on: { click: _vm.uploadBuildingCode },
                    },
                    [_vm._v(" 下载楼栋二维码 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ $index }) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.listQuery.pageNumber - 1) *
                              _vm.listQuery.pageSize +
                              $index +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "房源ID", prop: "houseId" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商户", prop: "shopName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "小区名称",
              prop: "communityName",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "楼栋名称", prop: "buildingName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "房间号", prop: "houseName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "省/市/区", prop: "productName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.province) +
                        "/" +
                        _vm._s(scope.row.city) +
                        "/" +
                        _vm._s(scope.row.district) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "房源状态", prop: "houseStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.houseStatus == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" 空房可租 "),
                        ])
                      : _vm._e(),
                    scope.row.houseStatus == 3
                      ? _c("el-tag", [_vm._v("在租中")])
                      : _vm._e(),
                    scope.row.houseStatus == 2
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" 空房不可租 "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "上架状态", prop: "shelfStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.houseStatus == 1 && scope.row.shelfStatus === 1
                      ? _c("span", [_vm._v(" 已上架 ")])
                      : _vm._e(),
                    scope.row.houseStatus == 1 &&
                    (scope.row.shelfStatus === 0 || scope.row.shelfStatus === 2)
                      ? _c("span", [_vm._v(" 未上架 ")])
                      : _vm._e(),
                    scope.row.houseStatus == 3 || scope.row.houseStatus == 2
                      ? _c("span", [_vm._v(" 不可上架 ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              prop: "productName",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detail(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.shelfStatus !== 1,
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setHot(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.hotFlag == 1 ? "取消热门" : "设置热门"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.shelfStatus !== 1,
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.posterImg(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 海报 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: scope.row.shelfStatus !== 1,
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.codeImg(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 二维码 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Dialog", {
        attrs: {
          "confirm-bnt-text": _vm.dailog.confirmBntText,
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          title: _vm.dailog.title,
          visible: _vm.dailog.visible,
          width: _vm.dailog.width,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handleConfirm,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "uploadBuildingData",
                    attrs: {
                      "label-width": "90px",
                      model: _vm.uploadBuildingData,
                      rules: _vm.rules,
                      size: "small",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "小区名称", prop: "communityId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请输入关键词搜索小区",
                                  "value-key": "id",
                                },
                                on: { change: _vm.changeCommunityName },
                                model: {
                                  value: _vm.uploadBuildingData.community,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.uploadBuildingData,
                                      "community",
                                      $$v
                                    )
                                  },
                                  expression: "uploadBuildingData.community",
                                },
                              },
                              _vm._l(_vm.communityOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "楼栋名称", prop: "buildingId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请输入关键词搜索楼栋",
                                  "value-key": "id",
                                },
                                on: { change: _vm.changeBuilding },
                                model: {
                                  value: _vm.uploadBuildingData.building,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.uploadBuildingData,
                                      "building",
                                      $$v
                                    )
                                  },
                                  expression: "uploadBuildingData.building",
                                },
                              },
                              _vm._l(_vm.buildingOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Pagination", {
        attrs: {
          limit: _vm.listQuery.pageSize,
          page: _vm.listQuery.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNumber", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _vm.squareVisible
        ? _c("HouseSquareDetail", {
            attrs: {
              "form-data": _vm.formData,
              "square-visible": _vm.squareVisible,
            },
            on: {
              "update:squareVisible": function ($event) {
                _vm.squareVisible = $event
              },
              "update:square-visible": function ($event) {
                _vm.squareVisible = $event
              },
            },
          })
        : _vm._e(),
      _vm.downPosterVisible
        ? _c("DownPosterImgVue", {
            attrs: {
              "down-poster-visible": _vm.downPosterVisible,
              "poster-img-data": _vm.posterImgData,
            },
            on: {
              "update:downPosterVisible": function ($event) {
                _vm.downPosterVisible = $event
              },
              "update:down-poster-visible": function ($event) {
                _vm.downPosterVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }