<template>
  <div class="index-container meter-reading">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="楼栋名称"
            />
          </el-form-item>
          <el-form-item label="房间号">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="房间号"
            />
          </el-form-item>
          <el-form-item label="在线状态">
            <el-select v-model="listQuery.isOnline" placeholder="请选择状态">
              <el-option label="不在线" :value="0" />
              <el-option label="在线" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <vab-query-form-top-panel>
      <el-form ref="form" :inline="true">
        <el-form-item>
          <!-- <el-button
            icon="el-icon-plus"
            native-type="submit"
            type="primary"
            @click="handleAdd"
          >
            添加小区
          </el-button> -->
        </el-form-item>
      </el-form>
    </vab-query-form-top-panel>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备编号" prop="deviceNo" />
      <el-table-column align="center" label="设备类型" prop="typeNumber">
        <template slot-scope="scope">
          <span v-if="scope.row.typeNumber === 'SMART_GATEWAY'">智能网关</span>
          <span v-if="scope.row.typeNumber === 'SMART_ELECTRIC_METER'">
            电表
          </span>
          <span v-if="scope.row.typeNumber === 'SMART_LOCK'">智能门锁</span>
          <span v-if="scope.row.typeNumber === 'SMART_WATER_METER'">水表</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备电量" prop="surplusNum" />
      <el-table-column align="center" label="绑定商户" prop="shopName" />
      <el-table-column align="center" label="绑定楼栋" prop="buildingName" />
      <el-table-column align="center" label="绑定房源" prop="houseName" />
      <el-table-column align="center" label="绑定租客" prop="tenantName" />
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="handleKeySetting(scope.row)">
            钥匙设置
          </el-button>
          <el-button type="text" @click="handleRemoteLockKey(scope.row)">
            远程开锁
          </el-button>
          <el-button type="text" @click="handleOpenLockRecords(scope.row)">
            开锁记录
          </el-button>
          <el-button type="text" @click="handleAuthorizationRecords(scope.row)">
            授权记录
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <KeySettingsVue ref="keySettingRef" />
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
    <openLockRecordsVue ref="openLockRecordsRef" />
    <authorizationRecordVue ref="authorizationRecordRef" />
  </div>
</template>

<script>
  import { smartDevice } from '@/api/build/newBuild'
  import Pagination from '@/components/pagination.vue'
  import axios from 'axios'
  import { GD_API_WEB_SERVER_KEY, GD_HTTPS_URL } from '@/config'
  import { regionList } from '@/api/system/user'
  import {
    deleteCommunityApi,
    addCommunityApi,
    editCommunityApi,
  } from '@/api/build/community'
  import { loadMap } from '@/utils/t-map'
  import KeySettingsVue from './ components/key-settings.vue'
  import { remoteLockKey } from '@/api/build/build'
  import openLockRecordsVue from './ components/open-lock-records.vue'
  import authorizationRecordVue from './ components/authorization-record.vue'
  export default {
    name: 'CommunityManagement',
    components: {
      Pagination,
      KeySettingsVue,
      openLockRecordsVue,
      authorizationRecordVue,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      var checkAddressNumber = (rule, value, callback) => {
        if (value > 50) {
          callback(new Error('地址不能超过50个字符!'))
        } else {
          callback()
        }
      }
      // var checkDescNumber = (rule, value, callback) => {
      //   if (value > 58) {
      //     callback(new Error('地址不能超过58个字符!'))
      //   } else {
      //     callback()
      //   }
      // }
      return {
        areaList: [],
        areaProps: {
          value: 'code',
          label: 'name',
          children: 'children',
        },
        shopData: [],
        listQuery: {
          typeNumberList: 'SMART_LOCK',
          pageNumber: 1,
          pageSize: 20,
          shopName: undefined,
          buildingName: undefined,
          houseName: undefined,
          areaCode: undefined,
          isOnline: undefined,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
      }
    },
    created() {
      this.fetchData()
      this.getRegiList()
    },
    methods: {
      handleKeySetting(records) {
        this.$refs.keySettingRef.handleShow(records)
      },
      handleRemoteLockKey(records) {
        this.$confirm('远程开锁后，5s后将自动关锁', '是否远程开锁？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          remoteLockKey(records.id).then(() => {
            this.$message({
              type: 'success',
              message: '开锁成功!',
            })
            this.fetchData()
          })
        })
      },
      handleOpenLockRecords(records) {
        this.$refs.openLockRecordsRef.show(records)
      },
      handleAuthorizationRecords(records) {
        this.$refs.authorizationRecordRef.show(records)
      },
      handleChange(val) {
        this.listQuery.provinceCode = val[0]
        this.listQuery.cityCode = val[1]
        this.listQuery.districtCode = val[2]
        this.listQuery.streetCode = val[3]
      },
      getRegiList() {
        regionList().then((res) => {
          if (res.code === 0) {
            this.areaList = res.data
            console.log(this.areaList)
          }
        })
      },

      setSelectRows(val) {
        this.selectRows = val
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      fetchData() {
        this.loading = true
        let params = { ...this.listQuery }
        delete params.areaCode
        smartDevice(params)
          .then((res) => {
            if (res.code === 0) {
              const { records, total } = res.data
              this.tableData = records
              this.totalCount = +total
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      //重置
      resetQuery() {
        this.listQuery = {
          typeNumberList: 'SMART_LOCK',
          pageNumber: 1,
          pageSize: 20,
          shopName: undefined,
          buildingName: undefined,
          houseName: undefined,
          areaCode: undefined,
          isOnline: undefined,
        }
        this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .box-card.el-card {
    border: none !important;
  }

  .png-tip {
    color: #ccc;
    font-size: 12px;
    font-weight: 400;
    line-height: 0;
    letter-spacing: 0;
    text-align: left;
  }
</style>
