import { render, staticRenderFns } from "./update-password.vue?vue&type=template&id=47f76264&scoped=true"
import script from "./update-password.vue?vue&type=script&lang=js"
export * from "./update-password.vue?vue&type=script&lang=js"
import style0 from "./update-password.vue?vue&type=style&index=0&id=47f76264&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f76264",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/zss-service-platform/frontend/zss-rental-service-admin-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47f76264')) {
      api.createRecord('47f76264', component.options)
    } else {
      api.reload('47f76264', component.options)
    }
    module.hot.accept("./update-password.vue?vue&type=template&id=47f76264&scoped=true", function () {
      api.rerender('47f76264', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/build/smartdoorlock/ components/update-password.vue"
export default component.exports