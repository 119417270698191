<template>
  <div class="index-container">
    <!-- <el-radio-group
      v-model="radio"
      style="margin-bottom: 20px"
      @change="selectType"
    >
      <el-radio-button label="电表" />
      <el-radio-button label="水表" />
    </el-radio-group> -->
    <el-form label-width="80px" :model="listQuery" size="small">
      <el-row>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="楼栋名称">
            <el-input
              v-model="listQuery.buildingName"
              clearable
              placeholder="请输入楼栋名称"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="房间号">
            <el-input
              v-model="listQuery.houseName"
              clearable
              placeholder="请输入房间号"
              @keyup.enter.native="getList"
            />
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="类型">
            <el-select
              v-model="listQuery.subjectId"
              clearable
              placeholder="请选择类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in subjectIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="抄表场景">
            <el-select
              v-model="listQuery.readReason"
              clearable
              placeholder="请选择抄表原因"
              style="width: 100%"
            >
              <el-option
                v-for="item in readReason"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="抄表方式">
            <el-select
              v-model="listQuery.readType"
              clearable
              placeholder="请选择抄表方式"
              style="width: 100%"
            >
              <el-option
                v-for="item in readType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item label="抄表时间">
            <el-date-picker
              v-model="listQuery.meterDate"
              placeholder="选择日期"
              style="width: 100%"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>

        <el-col
          :md="{ span: 8 }"
          :sm="{ span: 8 }"
          :xl="{ span: 8 }"
          :xs="{ span: 24 }"
        >
          <el-form-item>
            <el-button
              icon="el-icon-search"
              size="mini"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" size="mini" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="抄表序号"
        prop="id"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        show-overflow-tooltip
        width="200"
      />
      <!-- <el-table-column
        align="center"
        label="小区名称"
        prop=""
        show-overflow-tooltip
        width="200"
      /> -->
      <el-table-column align="center" label="房间号" prop="houseName" />
      <el-table-column
        align="center"
        label="类型"
        prop="readType"
        show-overflow-tooltip
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.subjectId === '3002'">水表</span>
          <span v-if="scope.row.subjectId === '3001'">电表</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="总用量" prop="totalUsedVal" />
      <!-- <el-table-column
        align="center"
        label="剩余量"
        prop="usedVal"
        show-overflow-tooltip
      /> -->
      <el-table-column
        align="center"
        label="本期读数"
        prop="meterVal"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="上期读数"
        prop="lastMeterVal"
        show-overflow-tooltip
      />

      <!-- <el-table-column
        align="center"
        label="用量"
        prop="totalUsedVal"
        show-overflow-tooltip
      /> -->

      <el-table-column
        align="center"
        label="用量"
        prop="usedVal"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        label="抄表场景"
        show-overflow-tooltip
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.readReason == 1">账单结算</span>
          <span v-if="scope.row.readReason == 2">绑定新表</span>
          <span v-if="scope.row.readReason == 3">更换旧表</span>
          <span v-if="scope.row.readReason == 4">租客退租</span>
          <span v-if="scope.row.readReason == 5">水电抄表</span>
          <span v-if="scope.row.readReason == 6">租客入住</span>
          <span v-if="scope.row.readReason == 7">水电抄表</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="抄表方式"
        prop="readTypeName"
        show-overflow-tooltip
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.readType == 0">手动录入</span>
          <span v-if="scope.row.readType == 1">自动获取</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="抄表时间"
        prop="meterDate"
        width="200"
      />
      <!-- <el-table-column align="center" label="操作" prop="address" width="50">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column> -->
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
    >
      <template #content>
        <el-descriptions
          border
          class="margin-top"
          :column="2"
          :size="size"
          style="font-size: 15px"
          title=""
        >
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ drawerData.contractPerson }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">楼栋名称</template>
            {{ drawerData.buildingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">房源名称</template>
            {{ drawerData.houseName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单项目名称</template>
            {{ drawerData.subjectName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">抄表值</template>
            {{ drawerData.meterVal }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">上次抄表日期</template>
            {{ drawerData.lastMeterDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本次抄表值</template>
            {{ drawerData.meterVal }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">本次抄表日期</template>
            {{ drawerData.meterDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">金额</template>
            <!-- {{ drawerData.endDate }} -->
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">抄表人</template>
            <!-- {{ drawerData.endDate }} -->
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{ drawerData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">附件</template>
            {{ drawerData.attachment }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{ drawerData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">修改时间</template>
            {{ drawerData.updateTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { meterList } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'Meter',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        dailog: {
          height: null,
          title: '',
          visible: false,
          visibleService: false,
          width: '',
          showfoot: false,
        },
        radio: '电表',
        options: [
          { value: 1, label: '租赁中' },
          { value: 0, label: '已完结' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          // subjectId: '3001',
          subjectId: '',
          readType: '',
          readReason: '',
          meterDate: null,
          houseName: '',
          shopName: '',
          buildingName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
        readType: [
          { value: 0, label: '手动录入' },
          { value: 1, label: '自动获取' },
        ],
        readReason: [
          { value: 1, label: '账单结算' },
          { value: 2, label: '绑定新表' },
          { value: 3, label: '更换旧表' },
          { value: 4, label: '租客退租' },
          { value: 5, label: '水电抄表' },
          { value: 6, label: '租客入住' },
          { value: 7, label: '水电抄表' },
        ],
        subjectIdList: [
          { value: '', label: '全部' },
          { value: '3002', label: '水表' },
          { value: '3001', label: '电表' },
        ],
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      handleCloseDialog() {
        this.dailog.visible = false
      },
      selectType(val) {
        this.listQuery.subjectId = val === '水表' ? '3002' : '3001'
        this.getList()
      },
      getList() {
        this.loading = true
        let params = {
          ...this.listQuery,
          meterDateStart: this.listQuery.meterDate
            ? this.listQuery.meterDate[0]
            : undefined,
          meterDateEnd: this.listQuery.meterDate
            ? this.listQuery.meterDate[1]
            : undefined,
        }
        delete params.meterDate
        meterList(params).then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.tableData = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      handleCheckDetail(row) {
        console.log(row)
        this.dailog.visible = true
        this.dailog.title = '详情'
        this.drawerData = row
      },
      handleClose() {
        this.drawer = false
      },
      reset() {
        for (let key in this.listQuery) {
          this.listQuery[key] = ''
        }
        this.listQuery.pageNumber = 1
        this.listQuery.pageSize = 20
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .left {
      display: flex;
    }
  }

  ::v-deep() .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    font-size: 15px !important;
  }
</style>
