var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container meter-reading" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "80px",
                    model: _vm.listQuery,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小区名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "小区名称" },
                        model: {
                          value: _vm.listQuery.communityName,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "communityName", $$v)
                          },
                          expression: "listQuery.communityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          options: _vm.areaList,
                          placeholder: "请选择地区",
                          props: _vm.areaProps,
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.listQuery.areaCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "areaCode", $$v)
                          },
                          expression: "listQuery.areaCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetQuery },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vab-query-form-top-panel",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(" 添加小区 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.tableData, stripe: "" },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "小区ID", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "小区名称", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "所属地区", prop: "productName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.province) +
                        "/" +
                        _vm._s(scope.row.city) +
                        "/" +
                        _vm._s(scope.row.district) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "详细地址", prop: "address" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "关联房东", prop: "shopCount" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openShop(scope.row.relevanceShops)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.shopCount > 0
                                ? scope.row.shopCount + " 位"
                                : "无关联"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          showfoot: _vm.dailog.showfoot,
          title: _vm.dailog.title,
          visible: _vm.dailog.visible,
          width: _vm.dailog.width,
        },
        on: { handleCloseDialog: _vm.handleCloseDialog },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-table",
                  {
                    attrs: { border: "", data: _vm.shopData, stripe: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("el-image", {
                              staticClass: "vab-data-empty",
                              attrs: {
                                src: require("@/assets/empty_images/data_empty.png"),
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "商户名",
                        prop: "shopName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleShopDetail(
                                        scope.row.shopId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 查看 ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visibleAddOrEdit,
          title: _vm.dailog.title,
          top: _vm.dailog.top,
          visible: _vm.dailog.visibleAddOrEdit,
          width: _vm.dailog.width,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handleConfirm,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    attrs: {
                      "label-width": "120px",
                      model: _vm.formData,
                      rules: _vm.rules,
                      size: "small",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "小区名称", prop: "name" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入小区名称",
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "所属地区", prop: "provinceCode" },
                          },
                          [
                            _c("CommunityRegionPicker", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                "default-text": _vm.getRegionText("address"),
                                enable: _vm.enablePicker,
                                placeholder: "请选择省/市/区/街道",
                                prop: "address",
                              },
                              on: { change: _vm.handleRegionChange },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "详细地址", prop: "address" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  filterable: "",
                                  loading: _vm.addressLoading,
                                  placeholder: "请输入关键词搜索小区地址",
                                  remote: "",
                                  "remote-method": _vm.remoteMethod,
                                  "reserve-keyword": "",
                                  "value-key": "id",
                                },
                                on: { change: _vm.changeAddress },
                                model: {
                                  value: _vm.formData.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "address", $$v)
                                  },
                                  expression: "formData.address",
                                },
                              },
                              _vm._l(
                                _vm.addressOptions,
                                function (item, index) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: { label: item.name, value: item },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            "font-size": "8px",
                                            color: "#8492a6",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.address.length > 0
                                                  ? item.address.length > 130
                                                    ? item.district
                                                    : item.address
                                                  : item.district
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-form-item", [
                          _c("div", {
                            staticStyle: { width: "90%", height: "260px" },
                            attrs: { id: "mapContainerbuilding" },
                          }),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "小区图片" } },
                          [
                            _c("JMUpload", {
                              attrs: { multiple: false, number: 1 },
                              model: {
                                value: _vm.formData.img,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "img", $$v)
                                },
                                expression: "formData.img",
                              },
                            }),
                            _c("span", { staticClass: "png-tip" }, [
                              _vm._v(
                                " 图片尺寸: 660x330PX，或者宽高比2:1， 图片大小请限制在10MB内 "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "小区简介", prop: "description" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入小区简介最多可输入58字",
                                rows: 4,
                                type: "textarea",
                              },
                              model: {
                                value: _vm.formData.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "description", $$v)
                                },
                                expression: "formData.description",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Pagination", {
        attrs: {
          limit: _vm.listQuery.pageSize,
          page: _vm.listQuery.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNumber", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }