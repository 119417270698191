<template>
  <el-dialog
    :before-close="handleClose"
    title="详情"
    :visible="squareVisible"
    width="880px"
  >
    <el-form ref="formData" label-width="100px" :model="formData" size="small">
      <div class="card-title">房源基本信息</div>
      <el-descriptions border class="margin-top" :column="2" size="medium">
        <el-descriptions-item>
          <template slot="label">房间名称</template>
          {{ formData.publishName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">所属小区</template>
          {{ formData.communityName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">商户名称</template>
          {{ formData.shopName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">房源状态</template>
          <el-tag v-if="formData.houseStatus == 1" type="success">
            空房可租
          </el-tag>

          <el-tag v-if="formData.houseStatus == 3">在租中</el-tag>

          <el-tag v-if="formData.houseStatus == 2" type="danger">
            空房不可租
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">房源ID</template>
          {{ formData.houseId }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">房型</template>
          {{ formData.houseShape }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">业主联系人1</template>
          {{ formData.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">业主联系人2</template>
          {{ formData.contactPhone || '' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">所属地区</template>
          {{
            formData.province
              ? formData.province
              : '' + formData.city + formData.district
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">所在楼层</template>
          {{ formData.floor }}层
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">详细地区</template>
          {{ formData.address }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">租客广场</template>
          {{ formData.publishStatus == 1 ? '已添加' : '未添加' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">面积</template>
          {{ formData.area }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">租金</template>
          {{ formData.publishRentPrice }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">朝向</template>
          {{ formData.toward ? formData.toward : '' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">房源类型</template>
          {{ houseTypeFilter(formData.houseType) || '--' }}
        </el-descriptions-item>
      </el-descriptions>

      <el-row>
        <el-row>
          <el-divider />
        </el-row>
        <div v-if="formData.housePhotoList?.length > 0" class="card-title">
          房源照片
        </div>
        <el-descriptions border class="margin-top" :column="1" size="medium">
          <el-descriptions-item
            v-for="(item, idx) in formData.housePhotoList"
            :key="idx"
          >
            <template slot="label">{{ item.placeTag }}</template>
            <img :src="item.url" style="width: 300px; height: 300px" />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{ formData.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    name: '',
    props: {
      squareVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      formData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      //转换房源类型 0-租房，1-商铺，2-公寓，3-厂房
      houseTypeFilter(type) {
        const houseType = {
          0: '租房',
          1: '商铺',
          2: '公寓',
          3: '厂房',
        }
        return houseType[type]
      },
      handleClose() {
        this.$emit('update:squareVisible', false)
      },
      handleConfirm() {
        this.$emit('update:squareVisible', false)
      },
    },
  }
</script>
<style scoped lang="scss">
  .card-title {
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: bold;
  }
</style>
