<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="dailog.showfoot"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCancel"
    @handleConfirm="handleConfirm"
  >
    <template #content>
      <el-form
        ref="form"
        :inline="true"
        label-width="120px"
        :model="listQuery"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item label="接收人信息" prop="memberIdList">
          <el-select
            v-model="listQuery.memberIdList"
            placeholder="请选择接收人信息"
            style="width: 360px"
          >
            <el-option
              v-for="item in memberIdOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="接收方式" prop="noticeWays">
          <el-select
            v-model="listQuery.noticeWays"
            multiple
            placeholder="请选择接收方式"
            style="width: 360px"
          >
            <el-option
              v-for="item in noticeWaysOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import { housePeopleHousePeople } from '@/api/build/build'
  export default {
    components: {
      Dialog,
    },
    data() {
      return {
        rules: {
          noticeWays: [
            {
              required: true,
              message: '请选择接收方式',
              trigger: 'change',
            },
          ],
          memberIdList: [
            { required: true, message: '请选择接收人信息', trigger: 'change' },
          ],
        },
        dailog: {
          height: 400,
          title: '授权用户',
          visible: false,
          showfoot: true,
          visibleAddOrEdit: false,
          width: '520px',
          top: '5vh',
        },
        listQuery: {
          noticeWays: [],
          memberIdList: undefined,
        },
        noticeWaysOptions: [
          {
            label: '公众号',
            value: 1,
          },
          {
            label: '短信',
            value: 2,
          },
        ],
        memberIdOptions: [],
      }
    },
    methods: {
      getHousePeopleHousePeople(records) {
        housePeopleHousePeople({ houseId: records.houseId }).then((res) => {
          if (res.code === 0) {
            this.memberIdOptions = res.data
          }
        })
      },
      show(records) {
        this.dailog.visible = true
        this.listQuery = { ...records }
        this.getHousePeopleHousePeople(records)
      },
      handleCancel() {
        this.handleReset()
        this.dailog.visible = false
      },
      handleReset() {
        this.$refs['form'].resetFields()
      },
      handleConfirm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              noticeWays: this.listQuery.noticeWays.join(',') || '',
              memberIdList: [this.listQuery.memberIdList],
              keyId: this.listQuery.id,
            }
            this.$emit('handleSubmitAuthorizedUser', params)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    color: #8a96a8;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
</style>
