<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :showfoot="dailog.showfoot"
    :title="dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCancel"
  >
    <template #content>
      <div class="content-header">
        说明：通过自定义授权规则，可以做到房源关联业务场景自动触发回收密码和发送密码能力，降低管理风险。
      </div>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="场景" prop="name" />
        <el-table-column align="center" label="方式" prop="shopName">
          <template slot-scope="scope">
            {{
              keyTypeOptions.find((item) => +item.value === scope.row.keyType)
                .label
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型" prop="shopName">
          <template slot-scope="scope">
            {{
              validityTypeOptions.find(
                (item) => +item.value === scope.row.validityType
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="授权人" prop="memberName" />
        <el-table-column align="center" label="状态" prop="lockStatus">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="success">正常</el-tag>
            <el-tag v-if="scope.row.status === 2" type="info">待匹配</el-tag>
            <el-tag v-if="scope.row.status === 0" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="有效时间" prop="createTime">
          <template slot-scope="scope">
            <span v-if="scope.row.validityType === 2">永久</span>
            <span v-if="scope.row.validityType === 3">
              {{ scope.row.createTime }}至{{ scope.row.endDate }}
            </span>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="pageSize"
        :page.sync="pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import { getLockMemberRelation } from '@/api/build/build'
  import Pagination from '@/components/pagination.vue'

  export default {
    components: {
      Dialog,
      Pagination,
    },
    data() {
      return {
        loading: false,
        dailog: {
          height: 600,
          title: '授权记录',
          visible: false,
          showfoot: false,
          visibleAddOrEdit: false,
          width: '800px',
          top: '5vh',
        },
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        tableData: [],
        memberTypeOptions: [
          {
            label: '普通用户',
            value: 1,
          },
          {
            label: '租户',
            value: 2,
          },
          {
            label: '渠道商',
            value: 3,
          },
          {
            label: '超级管理员',
            value: 4,
          },
        ],
        validityTypeOptions: [
          // {
          //   label: '一次性',
          //   value: 1,
          // },
          {
            label: '永久',
            value: 2,
          },
          {
            label: '时间范围',
            value: 3,
          },
        ],
        keyTypeOptions: [
          // {
          //   label: '电子钥匙',
          //   value: 1,
          // },
          {
            label: '密码钥匙',
            value: 2,
          },
          {
            label: 'ic卡',
            value: 3,
          },
          {
            label: '指纹',
            value: 4,
          },
          // {
          //   label: '人脸',
          //   value: 5,
          // },
        ],
      }
    },
    methods: {
      show(records) {
        this.dailog.visible = true
        this.pageNumber = 1
        this.pageSize = 10
        this.listQuery = records
        this.handleSearch()
      },
      handleCancel() {
        this.handleReset()
        this.dailog.visible = false
      },
      handleReset() {},
      handleSearch() {
        this.loading = true
        getLockMemberRelation({
          houseId: this.listQuery.houseId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
          .then((res) => {
            this.tableData = res.data.records
            this.totalCount = +res.data.total
          })
          .finally(() => {
            this.loading = false
          })
      },
      fetchData(val) {
        this.pageSize = val.limit
        this.pageNumber = val.page + 1
        this.handleSearch()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #8a96a8;
    text-align: center;
  }
</style>
